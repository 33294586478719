import React from 'react'
import Services_Banner from '../Services_Banner/Services_Banner'
import Scaffolding_Accessories_content from './Scaffolding_Accessories_content/Scaffolding_Accessories_content'


const Scaffolding_Accessories = () => {
    return (
        <>
            <Services_Banner heading={
                "Scaffolding Accessories on Rent and Sale"
            } />
            <Scaffolding_Accessories_content />
        </>
    )
}

export default Scaffolding_Accessories