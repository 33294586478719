import React from "react";
import "./About_content.css";

const About_content = () => {
  return (
    <>
      <section className="About_content_sec ">
        <div className="container">
          <div className="backgrounddiv main-about-us-sec">
            <div className="heading-holder-section ">
              <h2 className="main-heading">About Us</h2>
              <h3 className="heading">About Us</h3>
            </div>

            <div className="abt-contdiv main-section">
              <div className="row">
                <div className="col-lg-6 mb-4">
                  <div className="sec-1">
                    <div className="text_holder">
                      <p className="content">
                        Since our inception in 1996, Choudhary scaffolding
                        contractors is a leading turnkey solution provider for
                        all types of{" "}
                        <b>
                          Scaffolding services on Rent & Sales like MS Cup lock
                          along with installation and dismantling, Certified
                          Aluminium trolley, FRP trolley, Airport ladder, A type
                          ladder and scaffolding accessories.
                        </b>{" "}
                      </p>

                      <p className="content">
                        With over decade’s we have continuously strived to
                        maintain the highest possible{" "}
                        <b>“Safety, Service and Commitment”</b>. This is our
                        principal, which makes us to stand upfront in queue for
                        efficient, economic, and leading reliable service
                        providing organization.{" "}
                        <b>
                          The speed of scaffolding erection and dismantling is
                          unique quality of Choudhary scaffolding contractors.
                        </b>
                      </p>

                      <p className="content">
                        We aim to provide key supplier in sale of all types of
                        scaffolding for all industrial, residential and
                        commercial needs. The aluminium and FRP scaffolding
                        products are generation ahead in term of state of art of
                        technology, light-weight, confined spaces, safe & secure
                        working conditions in extended heights indoor as well as
                        outdoor. These are highly versatile towers provide
                        strong working platform for verity of heights along with
                        Ideal speed of installation.
                      </p>

                      <p className="content">
                        The “Choudhary Scaffolding Contractor” enhances the
                        sophisticated interplay of quality and performance in
                        line with building regulation requirement and
                        underpinned by a manufacturing process of meeting the
                        most expecting quality standard in aluminium
                        scaffolding.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 mb-4">
                  <div className="sec-2">
                    <div className="img-div">
                      <img
                        className="immage"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/Images/aboutus/banner/aboutcont.png"
                        }
                      />
                    </div>
                    <div className="layerbg"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About_content;
