import React, { useState, useEffect, useContext } from "react";
import About_banner from './abt-banner/About_banner'
import About_content from './abt-content/About_content'
import About_values from './abt-values/About_values'
import About_team from './abt_team/About_team'
import About_whyschooseus from './abt-whyschoose/About_whyschooseus'
import { Context } from "../../utils/context";
import { getAllExcellence } from "../../utils/apis/common/Common";
function About_Us() {
  const [data, setData] = useState();
    const {  IMG_URL } = useContext(Context);
    const getAllData = async () => {
        const res = await getAllExcellence();
     
        if (res?.success) {
            setData(res?.data?.list);
        } else {
            console.error('Error fetching  data:', res?.error);
        }
    }
console.log(data,"data mmmmmmmmmmmmmmmmmm");

    useEffect(() => {
        getAllData();
    }, []);
  return (
    <>
      <About_banner />
      <About_content />
      <About_values />
      <About_team />
      {data?.length > 0 && <About_whyschooseus data={data} IMG_URL={IMG_URL}/>}
    </>
  )
}

export default About_Us