import React from "react";
import "./FRP_scaffolding_content.css";
import { Nav, Tab } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import FRP_Services_slider_section from "../../../Common_Component/FRP_Services_slider_section/FRP_Services_slider_section";

const FRP_scaffolding_content = () => {
  return (
    <>
      <section className="Scaffolding_on_Rent_content_holder FRP_scaffolding_content common-section">
        <div className="container">
          <div className="Scaffolding_on_Rent_content">
            <div className="tabs-holder">
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey="NARROW_WIDTH"
              >
                <div className="row">
                  <div className="col-md-12">
                    <Tab.Content className="outer-tab-content">
                      <Tab.Pane eventKey="NARROW_WIDTH">
                        <div className="NARROW_WIDTH_Contant">
                          <div className="row">
                            <div className="col-lg-5 col-md-5 col-sm-12">
                              <FRP_Services_slider_section />
                            </div>

                            <div className="col-lg-7 col-md-7 col-sm-12">
                              <div className="tab-content-holder">
                                <p>
                                  FRP (Fibreglass) electrical shockproof
                                  Scaffolding Trolley designed for situations
                                  where aluminum or steel scaffolding is
                                  unsuitable, this tower is non-conductive and
                                  non-sparking, making it perfect for areas with
                                  a risk of electrocution or fire.
                                </p>
                                <p>
                                  Made from non-corroding fiberglass, our FRP
                                  scaffolding is perfect for handling in all
                                  climates. Our tower is strong enough to hold
                                  any necessary tools and equipment safely and
                                  securely. Plus, with built-in ladders and
                                  ribbed rungs, you can be sure of a good grip
                                  even in poor conditions.
                                </p>
                                <p>
                                  Our fiberglass scaffolding towers are
                                  available in two slip-resistant platform
                                  lengths (1.8 and 2.5m) and in either single
                                  (850mm) or double (1450mm) widths, making them
                                  customizable to meet your specific needs.
                                </p>
                                <p>
                                  Additional features such as Snap locks and
                                  stabilizers increase the stability and prevent
                                  the swing which keeps the scaffold upright and
                                  strong in any conditions. FRP Scaffolding
                                  trolley have been designed to be assembled
                                  quickly and easily, even with minimal
                                  training. This can result in significant
                                  savings on labor costs, as well as time.
                                </p>
                              </div>

                              <hr />

                              <div className="inner-tabs-holder">
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="inner-tab-content">
                                      <div className="NARROW_WIDTH_Contant">
                                        <div className="inner-tab-content-holder">
                                          <div className="Advantages-list-holder">
                                            <h3 className="inner-heading-text">
                                              Benefits of FRP Scaffolding:
                                            </h3>
                                            <ol>
                                              <li>
                                                Corrosion Resistance: Ideal for
                                                use in harsh environments, FRP
                                                scaffolding resists corrosion
                                                from chemicals, moisture, and
                                                extreme temperatures.
                                              </li>

                                              <li>
                                                Lightweight: Easy to transport
                                                and assemble, reducing labour
                                                costs and improving site
                                                efficiency.
                                              </li>
                                              <li>
                                                High Strength-to-Weight Ratio:
                                                Supports heavy loads while
                                                minimizing material usage.
                                              </li>

                                              <li>
                                                Low Maintenance: Resistant to
                                                rot, rust, and damage from pests
                                                or weathering.
                                              </li>

                                              <li>
                                                Eco-Friendly: Made from
                                                recyclable materials and
                                                reducing the need for frequent
                                                replacements.
                                              </li>
                                            </ol>
                                          </div>

                                          <hr />

                                          <div className="Advantages-list-holder">
                                            <h3 className="inner-heading-text">
                                              Applications of FRP Scaffolding:
                                            </h3>
                                            <ol>
                                              <li>
                                                Construction: Ideal for building
                                                maintenance, repairs, and new
                                                construction projects.
                                              </li>

                                              <li>
                                                Industrial: Suitable for oil
                                                rigs, chemical plants, and other
                                                corrosive environments.
                                              </li>
                                              <li>
                                                Infrastructure: Bridges,
                                                highways, and public works
                                                projects benefit from FRP
                                                scaffolding's durability.
                                              </li>
                                            </ol>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <hr />

                              <div className="tab-content-holder">
                                <h3>Why Choose FRP Scaffolding?</h3>
                                <ol className="order-list-holder">
                                  <li>
                                    <h3 className="inner-heading-text">
                                      {" "}
                                      Cost-Effective
                                    </h3>
                                    <p className="text-content">
                                      Reduces labor and material costs while
                                      extending product lifespan.
                                    </p>
                                  </li>

                                  <li>
                                    <h3 className="inner-heading-text">
                                      {" "}
                                      Safety
                                    </h3>
                                    <p className="text-content">
                                      Minimizes risk with its slip-resistant
                                      surface and sturdy structure.
                                    </p>
                                  </li>

                                  <li>
                                    <h3 className="inner-heading-text">
                                      {" "}
                                      Innovation
                                    </h3>
                                    <p className="text-content">
                                      Embrace cutting-edge technology for
                                      improved site efficiency and worker
                                      safety.
                                    </p>
                                  </li>
                                </ol>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                </div>
              </Tab.Container>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FRP_scaffolding_content;
