import React, { useState, useEffect, useContext } from "react";
import "./Walking_With_Giants.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css/pagination";
import { Context } from "../../../utils/context";

function Walking_With_Giants({data}) {
  const giant = [
    {
      imgSrc: "/assets/Images/Home/Walking_With_Giants/giants-img-1.png",
    },
    {
      imgSrc: "/assets/Images/Home/Walking_With_Giants/giants-img-2.png",
    },
    {
      imgSrc: "/assets/Images/Home/Walking_With_Giants/giants-img-3.png",
    },
    {
      imgSrc: "/assets/Images/Home/Walking_With_Giants/giants-img-4.png",
    },
    {
      imgSrc: "/assets/Images/Home/Walking_With_Giants/giants-img-5.png",
    },
    {
      imgSrc: "/assets/Images/Home/Walking_With_Giants/giants-img-6.png",
    },
    {
      imgSrc: "/assets/Images/Home/Walking_With_Giants/giants-img-1.png",
    },
    {
      imgSrc: "/assets/Images/Home/Walking_With_Giants/giants-img-2.png",
    },
    {
      imgSrc: "/assets/Images/Home/Walking_With_Giants/giants-img-3.png",
    },
    {
      imgSrc: "/assets/Images/Home/Walking_With_Giants/giants-img-4.png",
    },
    {
      imgSrc: "/assets/Images/Home/Walking_With_Giants/giants-img-5.png",
    },
    {
      imgSrc: "/assets/Images/Home/Walking_With_Giants/giants-img-6.png",
    },
  ];

  
  const {  IMG_URL } = useContext(Context);
 
  return (
    <>
      <section className="Walking_With_Giants">
        <div className="container">
          <div className="Walking_With_Giants_holder">
            <div className="heading_holder">
              <h3 className="heading-text">Walking With Giants</h3>
            </div>

            <div className="Walking_With_Giants_slider_holder">
              <Swiper
                className="myswiper Walking_With_Giants_slider"
                spaceBetween={15}
                slidesPerView={6}
                navigation={{ clickable: true }}
                pagination={{ clickable: true }}
                modules={[Navigation, Pagination]}
                breakpoints={{
                  // When the window is >= 320px
                  320: {
                    slidesPerView: 4,
                    spaceBetween: 10,
                  },
                  // When the window is >= 640px
                  640: {
                    slidesPerView: 5,
                    spaceBetween: 10,
                  },
                  // When the window is >= 768px
                  768: {
                    slidesPerView: 6,
                    spaceBetween: 15,
                  },
                  // When the window is >= 1024px
                  1024: {
                    slidesPerView: 7,
                    spaceBetween: 15,
                  },
                }}
              >
                {data?.map((giants, index) => (
                  <SwiperSlide key={index}>
                    <div className="Walking_With_Giants_card">
                      <div className="img-holder">
                        <img
                          className="Walking_With_Giants_img"
                          src={IMG_URL + giants?.image}
                          alt="Walking_With_Giants_img"
                        />
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Walking_With_Giants;
