import React from "react";
import Scaffolding_on_Rent_content from "./Scaffolding_on_Rent_content/Scaffolding_on_Rent_content";
import Services_Banner from "../Services_Banner/Services_Banner";

function Scaffolding_on_Rent() {
  return (
    <>
      <Services_Banner heading={"Cup-Lock Scaffolding on Rent and Sales along with Installation and Dismantling"}/>
      <Scaffolding_on_Rent_content />
    </>
  );
}

export default Scaffolding_on_Rent;
