import React from "react";
import "./Scaffolding_Accessories_content.css";
import { Col, Container, Row } from "react-bootstrap";

const Scaffolding_Accessories_content = () => {
  return (
    <section className="scaff-text-section common-section">
      <Container>
        {/* Accessory 1: COUPLER */}
        <div className="grey-bgg-main">
          <Row>
            <Col
              xl={1}
              lg={2}
              md={2}
              sm={3}
              xs={12}
              className="card-heading-holder"
            >
              <div className="main-blur-head">
                <h5 className="blur-head-title">01.</h5>
                <h5 className="blur-head-title">COUPLER</h5>
              </div>
            </Col>
            <Col xl={2} lg={4} md={3} sm={3} xs={12}>
              <div className="white-bggg-scaff">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/Images/Services/Scaffolding_Accessories/COUPLER.png"
                  }
                  className="accessories-img"
                  alt="COUPLER"
                />
              </div>
            </Col>
            <Col xl={10} lg={8} md={9} sm={9} xs={12}>
              <div className="second-seccc-main ms-xl-4 ms-lg-0 ">
                <h5 className="heading-scaff">01. COUPLER</h5>
                <div className="content-holder">
                  <div className="text-holder">
                    <p>
                      <b>
                        Scaffolding couplers are metal fittings used to connect
                        scaffolding tubes together, forming a secure and stable
                        structure. They:
                      </b>
                    </p>
                  </div>

                  <ul>
                    <li>Join tubes at right angles (90°) or other angles.</li>
                    <li>Provide structural support and stability.</li>
                    <li>Allow for quick assembly and disassembly.</li>
                  </ul>
                </div>

                <div className="content-holder">
                  <div className="text-holder">
                    <p>
                      <b>Types of scaffolding couplers:</b>
                    </p>
                  </div>

                  <ul>
                    <li> Right-angle couplers (90°)</li>
                    <li> Swivel couplers (allowing for adjustable angles)</li>
                    <li>Sleeve couplers (for connecting tubes end-to-end)</li>
                    <li> Clamp couplers (for securing tubes together)</li>
                  </ul>
                </div>

                <div className="content-holder">
                  <div className="text-holder">
                    <p>
                      <b>Key features and benefits:</b>
                    </p>
                  </div>

                  <ul>
                    <li> High load-bearing capacity</li>
                    <li> Durability and resistance to corrosion</li>
                    <li> Easy installation and removal</li>
                    <li>
                      <div className="text-holder">
                        <p>
                          <b>
                            {" "}
                            Versatility in scaffolding design and configuration
                            Scaffolding couplers are essential for ensuring the
                            safety and reliability of scaffolding structures in
                            various industries, including:
                          </b>
                        </p>
                      </div>
                      <ul>
                        <li>Construction</li>
                        <li>Oil and gas</li>
                        <li>Shipbuilding</li>
                        <li>Maintenance and repair</li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        {/* Accessory 2: U-JACK */}
        <div className="grey-bgg-main">
          <Row>
            <Col
              xl={1}
              lg={2}
              md={2}
              sm={3}
              xs={12}
              className="card-heading-holder"
            >
              <div className="main-blur-head">
                <h5 className="blur-head-title">02.</h5>
                <h5 className="blur-head-title">U-JACK</h5>
              </div>
            </Col>
            <Col xl={2} lg={4} md={3} sm={3} xs={12}>
              <div className="white-bggg-scaff">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/Images/Services/Scaffolding_Accessories/U-JACK.png"
                  }
                  className="accessories-img"
                  alt="U-JACK"
                />
              </div>
            </Col>
            <Col xl={10} lg={8} md={9} sm={9} xs={12}>
              <div className="second-seccc-main ms-xl-4 ms-lg-0 ">
                <h5 className="heading-scaff">02. U-JACK</h5>
                <div className="content-holder">
                  <div className="text-holder">
                    <p>
                      <b>
                        A U-Jack, also known as a U-Bracket or U-Clamp, is a
                        type of fastening device used to:
                      </b>
                    </p>
                  </div>

                  <ul>
                    <li> Support and stabilize scaffolding systems.</li>
                    <li> Secure pipes, tubes, or conduits.</li>
                    <li>
                      Hold materials in place during construction or
                      maintenance.
                    </li>
                  </ul>
                </div>

                <div className="content-holder">
                  <div className="text-holder">
                    <p>
                      <b>Characteristics:</b>
                    </p>
                  </div>

                  <ul>
                    <li> U-shaped design</li>
                    <li> Adjustable arms or screws</li>
                    <li>
                      {" "}
                      Durable materials (steel, aluminium, or stainless steel)
                    </li>
                  </ul>
                </div>

                <div className="content-holder">
                  <div className="text-holder">
                    <p>
                      <b>Applications:</b>
                    </p>
                  </div>

                  <ul>
                    <li> Construction sites</li>
                    <li> Oil and gas platforms</li>
                    <li> Shipbuilding and repair</li>
                    <li>Industrial maintenance</li>
                    <li>HVAC and plumbing installations</li>
                  </ul>
                </div>

                <div className="content-holder">
                  <div className="text-holder">
                    <p>
                      <b>Benefits:</b>
                    </p>
                  </div>

                  <ul>
                    <li> Easy installation and adjustment</li>
                    <li>
                      Versatility in clamping and supporting various sizes
                    </li>
                    <li>Reliable and secure holding capacity</li>
                    <li>
                      Cost-effective solution for temporary or permanent
                      installations
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        {/* Accessory 3: Base Jack */}
        <div className="grey-bgg-main">
          <Row>
            <Col
              xl={1}
              lg={2}
              md={2}
              sm={3}
              xs={12}
              className="card-heading-holder"
            >
              <div className="main-blur-head">
                <h5 className="blur-head-title">03.</h5>
                <h5 className="blur-head-title">Base Jack</h5>
              </div>
            </Col>
            <Col xl={2} lg={4} md={3} sm={3} xs={12}>
              <div className="white-bggg-scaff">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/Images/Services/Scaffolding_Accessories/Base-Jack.png"
                  }
                  className="accessories-img"
                  alt="Base Jack"
                />
              </div>
            </Col>
            <Col xl={10} lg={8} md={9} sm={9} xs={12}>
              <div className="second-seccc-main ms-xl-4 ms-lg-0 ">
                <h5 className="heading-scaff">03. Base Jack</h5>
                <div className="content-holder">
                  <div className="text-holder">
                    <p>
                      <b>
                        A Base Jack, also known as a Scaffolding Base Jack or
                        Adjustable Base Plate, is a fundamental component in
                        scaffolding systems:
                      </b>
                    </p>
                  </div>
                </div>

                <div className="content-holder">
                  <div className="text-holder">
                    <p>
                      <b>Purpose:</b>
                    </p>
                  </div>

                  <ul>
                    <li> Provides a stable base for scaffolding tubes. </li>
                    <li> Adjusts to uneven surfaces, ensuring levelness. </li>
                    <li>Distributes loads evenly, preventing settlement.</li>
                  </ul>
                </div>

                <div className="content-holder">
                  <div className="text-holder">
                    <p>
                      <b>Key Features:</b>
                    </p>
                  </div>

                  <ul>
                    <li> Adjustable screw or threaded stem.</li>
                    <li>Flat base plate for stability.</li>
                    <li> Compatible with various scaffolding tube sizes.</li>
                  </ul>
                </div>

                <div className="content-holder">
                  <div className="text-holder">
                    <p>
                      <b>Benefits:</b>
                    </p>
                  </div>

                  <ul>
                    <li> Ensures scaffolding stability and safety.</li>
                    <li>Accommodates uneven terrain or surfaces.</li>
                    <li>Easy adjustment and setup.</li>
                    <li>Compatible with various scaffolding systems.</li>
                  </ul>
                </div>

                <div className="content-holder">
                  <div className="text-holder">
                    <p>
                      <b>Applications:</b>
                    </p>
                  </div>

                  <ul>
                    <li> Construction sites.</li>
                    <li> Industrial maintenance.</li>
                    <li> Shipbuilding and repair.</li>
                    <li>Event staging and temporary structures.</li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        {/* Accessory 4: Joint Pin */}
        <div className="grey-bgg-main">
          <Row>
            <Col
              xl={1}
              lg={2}
              md={2}
              sm={3}
              xs={12}
              className="card-heading-holder"
            >
              <div className="main-blur-head">
                <h5 className="blur-head-title">04.</h5>
                <h5 className="blur-head-title">Joint Pin</h5>
              </div>
            </Col>
            <Col xl={2} lg={4} md={3} sm={3} xs={12}>
              <div className="white-bggg-scaff">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/Images/Services/Scaffolding_Accessories/Joint-Pin.png"
                  }
                  className="accessories-img"
                  alt="Base Jack"
                />
              </div>
            </Col>
            <Col xl={10} lg={8} md={9} sm={9} xs={12}>
              <div className="second-seccc-main ms-xl-4 ms-lg-0 ">
                <h5 className="heading-scaff">04. Joint Pin</h5>
                <div className="content-holder">
                  <div className="text-holder">
                    <p>
                      <b>
                        A Joint Pin, also known as a Scaffolding Joint Pin or
                        Scaffold Pin, is a vital component in scaffolding
                        systems:
                      </b>
                    </p>
                  </div>
                </div>

                <div className="content-holder">
                  <div className="text-holder">
                    <p>
                      <b>Purpose:</b>
                    </p>
                  </div>

                  <ul>
                    <li> Connects scaffolding tubes together.</li>
                    <li> Secures joints, ensuring structural stability. </li>
                  </ul>
                </div>

                <div className="content-holder">
                  <div className="text-holder">
                    <p>
                      <b>Types:</b>
                    </p>
                  </div>

                  <ul>
                    <li> Standard Joint Pin (for general scaffolding).</li>
                    <li>Heavy-Duty Joint Pin (for high-load applications).</li>
                    <li>Quick-Release Joint Pin (for easy disassembly).</li>
                  </ul>
                </div>

                <div className="content-holder">
                  <div className="text-holder">
                    <p>
                      <b>Applications:</b>
                    </p>
                  </div>

                  <ul>
                    <li> Construction sites.</li>
                    <li>Industrial maintenance.</li>
                    <li> Shipbuilding and repair.</li>
                    <li> Event staging and temporary structures.</li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        {/* Accessory 5: TUBE PLATFORM */}
        <div className="grey-bgg-main">
          <Row>
            <Col
              xl={1}
              lg={2}
              md={2}
              sm={3}
              xs={12}
              className="card-heading-holder"
            >
              <div className="main-blur-head">
                <h5 className="blur-head-title">05.</h5>
                <h5 className="blur-head-title">TUBE PLATFORM</h5>
              </div>
            </Col>
            <Col xl={2} lg={4} md={3} sm={3} xs={12}>
              <div className="white-bggg-scaff">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/Images/Services/Scaffolding_Accessories/TUBE-PLATFORM.png"
                  }
                  className="accessories-img"
                  alt="Base Jack"
                />
              </div>
            </Col>
            <Col xl={10} lg={8} md={9} sm={9} xs={12}>
              <div className="second-seccc-main ms-xl-4 ms-lg-0 ">
                <h5 className="heading-scaff">05. TUBE PLATFORM</h5>
                <div className="content-holder">
                  <div className="text-holder">
                    <p>
                      <b>
                        A Tube Platform, also known as a Scaffolding Tube
                        Platform or Tubular Platform, is a type of scaffolding
                        platform constructed from steel
                      </b>
                    </p>
                  </div>
                </div>

                <div className="content-holder">
                  <div className="text-holder">
                    <p>
                      <b>Key Features:</b>
                    </p>
                  </div>

                  <ul>
                    <li>Tubular frame for strength and stability</li>
                    <li> Wooden or metal decking for walking surface</li>
                    <li>Various lengths and widths available</li>
                    <li> Adjustable legs for uneven surfaces</li>
                  </ul>
                </div>

                <div className="content-holder">
                  <div className="text-holder">
                    <p>
                      <b>Benefits:</b>
                    </p>
                  </div>

                  <ul>
                    <li>Durable and long-lasting</li>
                    <li> High load-bearing capacity</li>
                    <li> Easy to assemble and disassemble</li>
                    <li> Versatile for various scaffolding applications</li>
                  </ul>
                </div>

                <div className="content-holder">
                  <div className="text-holder">
                    <p>
                      <b>Applications:</b>
                    </p>
                  </div>

                  <ul>
                    <li> Construction sites</li>
                    <li> Industrial maintenance</li>
                    <li>Shipbuilding and repair</li>
                    <li> Event staging and temporary structures</li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        {/* Accessory 6: Perforated Platform or Anti-skid platfom */}
        <div className="grey-bgg-main">
          <Row>
            <Col
              xl={1}
              lg={2}
              md={2}
              sm={3}
              xs={12}
              className="card-heading-holder"
            >
              <div className="main-blur-head">
                <h5 className="blur-head-title">06.</h5>
                <h5 className="blur-head-title">
                  Perforated Platform or Anti-skid platfom
                </h5>
              </div>
            </Col>
            <Col xl={2} lg={4} md={3} sm={3} xs={12}>
              <div className="white-bggg-scaff">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/Images/Services/Scaffolding_Accessories/Perforated-Platform.png"
                  }
                  className="accessories-img"
                  alt="Base Jack"
                />
              </div>
            </Col>
            <Col xl={10} lg={8} md={9} sm={9} xs={12}>
              <div className="second-seccc-main ms-xl-4 ms-lg-0 ">
                <h5 className="heading-scaff">
                  06. Perforated Platform or Anti-skid platfom
                </h5>
                <div className="content-holder">
                  <div className="text-holder">
                    <p>
                      <b>
                        A Perforated Platform, also known as a Perforated
                        Scaffolding Platform or Mesh Platform, is a type of
                        scaffolding platform featuring perforations (holes) for:
                      </b>
                    </p>
                  </div>
                </div>

                <div className="content-holder">
                  <div className="text-holder">
                    <p>
                      <b>Benefits:</b>
                    </p>
                  </div>

                  <ul>
                    <li>
                      {" "}
                      Its anti-skid specification gives rigid standing support
                      for foot
                    </li>
                    <li> Resistance against slippery part.</li>
                    <li> Enhanced visibility.</li>
                    <li> Increased light penetration.</li>
                  </ul>
                </div>

                <div className="content-holder">
                  <div className="text-holder">
                    <p>
                      <b>Characteristics:</b>
                    </p>
                  </div>

                  <ul>
                    <li> Perforated steel or aluminium construction.</li>
                    <li>
                      {" "}
                      Hole sizes and patterns vary (e.g., round, square, or
                      diamond).
                    </li>
                    <li> Durable and corrosion-resistant coatings.</li>
                  </ul>
                </div>

                <div className="content-holder">
                  <div className="text-holder">
                    <p>
                      <b>Applications:</b>
                    </p>
                  </div>

                  <ul>
                    <li> Construction sites.</li>
                    <li> Industrial maintenance.</li>
                    <li> Shipbuilding and repair.</li>
                    <li> Event staging and temporary structures.</li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
};

export default Scaffolding_Accessories_content;
