import "./Customer_Satisfaction_Stories.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";

const stories = [
  {
    text: "Working with Choudhary Scaffolding Contractor was a fantastic experience. Their attention to safety & quality was evident in every aspect of the project. The scaffolding was set up promptly & securely, ensuring our worksite ran smoothly. Highly recommended!",
    name: "- Bibhas Belel, Project Manager L & T Construction",
    imgSrc: "/assets/Images/Home/Customer_Satisfaction/Bibhas-Belel.png"
  },
  {
    text: "The team at Choudhary Scaffolding Contractor exceeded our expectations. They were reliable and punctual and provided top-notch scaffolding solutions that met all our needs. Their professionalism and efficiency were impressive.",
    name: "- Gaurav Goel, NIM Manager Schindler India Pvt. Ltd.",
    imgSrc: "/assets/Images/Home/Customer_Satisfaction/Gaurav-Goel.png"
  },
  {
    text: "From start to finish, Choudhary Scaffolding Contractor delivered outstanding scaffolding services. Their commitment to quality & customer support significantly impacted our project’s success. We appreciate their dedication and expertise",
    name: "- Karun Sharma, Piramal Estate ",
    imgSrc: "/assets/Images/Home/Customer_Satisfaction/Karun-Sharma.png"
  },
];

function Customer_Satisfaction_Stories() {
  return (
    <section className="Customer_Satisfaction_Stories">
      <div className="container">
        <div className="Customer_Satisfaction_Stories_holder">
          <div className="heading_holder">
            <h3 className="heading-text">Customer Satisfaction Stories</h3>
            <h4 className="subheading">What Our Clients Are Saying</h4>
          </div>

          <div className="Stories_slider_holder">
            <Swiper
              className="myswiper Stories_slider"
              spaceBetween={15}
              slidesPerView={3}
              navigation={true}
              modules={[Navigation]}
              breakpoints={{
                320: { slidesPerView: 1, spaceBetween: 5 },
                640: { slidesPerView: 1, spaceBetween: 5 },
                768: { slidesPerView: 2, spaceBetween: 10 },
                1024: { slidesPerView: 2, spaceBetween: 15 },
                1200: { slidesPerView: 3, spaceBetween: 15 },
              }}
            >
              {stories.map((story, index) => (
                <SwiperSlide key={index}>
                  <div className="Stories_card_holder">
                    <div className="content-holder">
                      <div className="story-text-holder">
                        <p>{story.text}</p>
                      </div>
                      <div className="story-name-text-holder">
                        <p>{story.name}</p>
                      </div>
                    </div>
                    <div className="profile-holder">
                      <div className="img-holder">
                        <img
                          className="Our_Services_img"
                          src={process.env.PUBLIC_URL + story.imgSrc}
                          alt="Customer profile"
                        />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Customer_Satisfaction_Stories;
