import React, { useState } from "react";
import "./Footer.css";
import { Link } from "react-router-dom";

function Footer() {

 
  return (
    <section className="footer-main-sec">
      <div className="container">
        <div className="row">
          <div className="col-lg-10 mx-auto">
            <div className="foott-cont">
              <div className="foottitlediv">
                <h1 className="title">Choudhary Scaffolding</h1>
              </div>

              <div className="menus-main-div">
                <div className="flex-menus">
                  <div className="menudiv">
                    <Link to="/" className="menu">
                      Home
                    </Link>
                  </div>
                  <div className="menudiv">
                    <Link to="/scaffolding-on-rent" className="menu" >
                      Services
                    </Link>
                  </div>
                  <div className="menudiv">
                    <Link to="/about-us" className="menu">
                      About Us
                    </Link>
                  </div>

                  <div className="menudiv">
                    <Link to="/contact-us" className="menu">
                      Contact Us
                    </Link>
                  </div>
                  <div className="menudiv">
                    <Link to="/terms-and-conditions" className="menu">
                      Terms and Conditions
                    </Link>
                  </div>
                  <div className="menudiv">
                    <Link to="/privacy-policy" className="menu">
                      Privacy Policy
                    </Link>
                  </div>
                </div>
              </div>

              <div className="addd-sec">
                <div className="row">
                  <div className="col-lg-12 mx-auto">
                    <div className="row">
                      <div className="col-lg-3 mb-4">
                        <div className="adddiv">
                          <p className="addtitle">
                            <span>Head Office </span>& Manufacturing Unit 1:
                          </p>
                          <p className="addtitle mt-2">
                            S. No 560,Sector 16, Raje Shivaji Nagar, Behind
                            Diagonal Mall, Straight to Kudalwadi Road, Next to
                            Rathi Hotel, , Jadhavwadi, Chikhali, Pune - 412114.
                          </p>
                        </div>
                      </div>

                      <div className="col-lg-3 mb-4">
                        <div className="adddiv">
                          <p className="addtitle">
                            <span>Manufacturing</span>Unit:2
                          </p>
                          <p className="addtitle mt-2">
                            9A, 4th Phase, 4th Cross, Keonics Layout, Peenya,
                            Bangalore – 560058
                          </p>
                        </div>
                      </div>

                      <div className="col-lg-3 mb-4">
                        <div className="adddiv">
                          <p className="addtitle">
                            <span>Manufacturing</span>Unit:3
                          </p>
                          <p className="addtitle mt-2">
                            Plot no 45 bayoth colony Near Hyderabad presidency
                            college Manikonda Hyderabad 500089
                          </p>
                        </div>
                      </div>

                      <div className="col-lg-3 mb-4">
                        <div className="adddiv">
                          <p className="addtitle">
                            <span>BRANCH </span>& WAREHOUSE:
                          </p>

                          <div className="text-holder">
                            <ul>
                              <li>
                                <p className="addtitle mt-2"> Mumbai </p>
                              </li>
                              <li>
                                <p className="addtitle mt-2"> Chennai </p>
                              </li>

                              <li>
                                <p className="addtitle mt-2">Goa</p>
                              </li>

                              <li>
                                <p className="addtitle mt-2"> Delhi</p>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="copyrightsdiv">
                <p>
                  © 2024 All Rights Reserved Terms Of Use | Privacy Policy
                  Powered by{" "}
                  <Link to={"https://profcymaglobal.com/"} target="_black">
                    <span>Profcyma</span>
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Footer;
