import React from "react";
import Services_Banner from "../Services_Banner/Services_Banner";
import Aluminium_Scaffolding_content from "./Aluminium_Scaffolding_content/Aluminium_Scaffolding_content";

function Aluminium_Scaffolding() {
  return (
    <>
      <Services_Banner heading={"Certified Aluminium Trolley On Rent and Sale"}/>
      <Aluminium_Scaffolding_content />
    </>
  );
}

export default Aluminium_Scaffolding;
