import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const postContactUs = async (data) => {
  try {
    return await postData(`contactusform`, data);
  } catch (error) {
    console.log(error);
  }
};
export const postInquiry = async (data) => {
  try {
    return await postData(`addinquiry`, data);
  } catch (error) {
    console.log(error);
  }
};


export const getAllProject = async () => {
  try {
    return await getData(`getprojects`);
  } catch (error) {
    console.log(error);
  }
}

export const getAllExcellence = async () => {
  try {
    return await getData(`getexcellence`);
  } catch (error) {
    console.log(error);
  }
}

export const getAllClient = async () => {
  try {
    return await getData(`getclient`);
  } catch (error) {
    console.log(error);
  }
}


export const getSuccess = async () => {
  try {
    return await getData(`getsuccess`);
  } catch (error) {
    console.log(error);
  }
}

export const getBanner = async () => {
  try {
    return await getData(`getbanners`);
  } catch (error) {
    console.log(error);
  }
}

