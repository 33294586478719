import React from "react";
import "./Type_Ladder_Rent_Content.css";
import { Nav, Tab } from "react-bootstrap";
import Type_Ladders_slider_section from "../../../Common_Component/Type_Ladders_slider_section/Type_Ladders_slider_section";

const Type_Ladder_Rent_Content = () => {
  return (
    <>
      <section className="Scaffolding_on_Rent_content_holder FRP_scaffolding_content common-section">
        <div className="container">
          <div className="Scaffolding_on_Rent_content">
            <div className="tabs-holder">
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey="NARROW_WIDTH"
              >
                <div className="row">
                  <div className="col-md-12">
                    <Tab.Content className="outer-tab-content">
                      <Tab.Pane eventKey="NARROW_WIDTH">
                        <div className="NARROW_WIDTH_Contant">
                          <div className="row">
                            <div className="col-lg-5 col-md-5 col-sm-12">
                              <Type_Ladders_slider_section />
                            </div>

                            <div className="col-lg-7 col-md-7 col-sm-12">
                              <div className="tab-content-holder">
                                <p>
                                It is made up of high tensile aluminium alloys. It is divided into two parts which can be joined together which makes it easier to handle. Step with lining provide firm foo hold grip, complete rubber, shoes and caps top prevent scratches on the floor and wall
                                </p>
                              </div>

                              <hr />

                              <div className="inner-tabs-holder">
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="inner-tab-content">
                                      <div className="NARROW_WIDTH_Contant">
                                        <div className="inner-tab-content-holder">
                                          <div className="Advantages-list-holder">
                                            <h3 className="inner-heading-text">
                                              Benefits:
                                            </h3>
                                            <ol>
                                              <li>
                                                It easy to handle and move
                                              </li>

                                              <li>
                                                Available in 3 feet to 12 feet
                                                as per requirement
                                              </li>
                                              <li>
                                                Rubber will help to get the grip
                                              </li>

                                              <li>
                                                Cap on top help to prevent the
                                                scratches.
                                              </li>

                                              <li>Light weight and durable.</li>
                                            </ol>
                                          </div>

                                    

                                         
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              
                            </div>
                          </div>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                </div>
              </Tab.Container>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Type_Ladder_Rent_Content;
