import React from "react";
import Services_Banner from "../Services_Banner/Services_Banner";
import FRP_scaffolding_content from "./FRP_scaffolding_content/FRP_scaffolding_content";

function FRP_scaffolding() {
  return (
    <>
      <Services_Banner heading={"FRP (Fibreglass) Electrical shockproof scaffolding trolley on Rent and Sale"}/>
      <FRP_scaffolding_content />
    </>
  );
}

export default FRP_scaffolding;
