import React from "react";
import Services_Banner from "../Services_Banner/Services_Banner";
import Type_Ladder_Rent_Content from "./Type_Ladder_Rent_Content/Type_Ladder_Rent_Content";

function Type_Ladder_Rent() {
  return (
    <>
      <Services_Banner heading={"A Type Ladders on Rent and Sale"}/>
      <Type_Ladder_Rent_Content />
    </>
  );
}

export default Type_Ladder_Rent;
