import React, { useState, useEffect, useContext } from "react";
import "./Your_Inquiries.css";
import { Button, Form } from "react-bootstrap";
import Btn_holder from "../../Common_Component/Btn_holder/Btn_holder";
import { Context } from "../../../utils/context";
import { postInquiry } from "../../../utils/apis/common/Common";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import classNames from "classnames";
import Successfull_Modal from "../../Common_Component/Successfull_Modal/Successfull_Modal";
function Your_Inquiries() {

  const { getData, IMG_URL } = useContext(Context);
  const { control, register, handleSubmit, getValues, setError, clearErrors, formState: { errors }, reset, watch, trigger, setValue } = useForm();

  const [successModal, setSuccessModal] = useState(false);
  const onSubmit = async (data) => {
    const res = await postInquiry(data)
    if (res?.success) {
      setSuccessModal(true);
      setTimeout(() => {
        setSuccessModal(false);
        reset();
      }, 3000);

    } else {
      if (res?.message?.email) {
        setError("email", { message: res?.message?.email });
      }
      if (res?.message?.contact_no) {
        setError("contact_no", { message: res?.message?.contact_no });
      }
      console.error('Error fetching  data:', res?.error);
    }
  }
  return (
    <>
      <section className="Your_Inquiries">
        <div className="container">
          <div className="Your_Inquiries_holder">
            <div className="row">
              <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-12">
                <div className="img-holder">
                  <img
                    className="Your_Inquiries_img"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/Images/Home/Your_Inquiries/your-inquiries-img.png"
                    }
                    alt="Your_Inquiries_img"
                  />
                </div>
              </div>
              <div className="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-12 Your_Inquiries_form">
                <div className="main-form-holder">
                  <div className="Your_Inquiries_form_holder">
                    <div>
                      <div className="heading_holder">
                        <h3 className="heading-text">
                          Your Inquiries Are Welcome!
                        </h3>
                      </div>

                      <div className="form_holder form-section">
                        <Form>
                          <div className="row">
                            <div className="col-xl-6 col-md-6 col-sm-6 col-12">
                              <Form.Group
                                className="Form-field-holder"
                                controlId="formBasicEmail"
                              >
                                <Form.Label>Enter First Name</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="name"
                                  placeholder="Enter First Name"
                                  {...register("first_name", {
                                    required: "Name is required",
                                  })}
                                  className={classNames("", {
                                    "is-invalid": errors?.first_name,
                                  })}
                                  onKeyDown={(event) => {
                                    if (!/[A-Z-a-z ]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                />
                                {errors.first_name && <div className="invalid-feedback">{errors.first_name.message}</div>}
                              </Form.Group>
                            </div>

                            <div className="col-xl-6 col-md-6 col-sm-6 col-12">
                              <Form.Group
                                className="Form-field-holder"
                                controlId="formBasicEmail"
                              >
                                <Form.Label>Enter Last Name</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="name"
                                  placeholder="Enter Last Name"
                                  {...register("last_name", {
                                    required: "Name is required",
                                  })}
                                  className={classNames("", {
                                    "is-invalid": errors?.last_name,
                                  })}
                                  onKeyDown={(event) => {
                                    if (!/[A-Z-a-z ]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                />
                                {errors.last_name && <div className="invalid-feedback">{errors.last_name.message}</div>}
                              </Form.Group>
                            </div>

                            <div className="col-xl-6 col-md-6 col-sm-6 col-12">
                              <Form.Group
                                className="Form-field-holder"
                                controlId="formBasicEmail"
                              >
                                <Form.Label>Enter Mail ID</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="email"
                                  placeholder="E-mail Address"
                                  {...register("email", {
                                    required: "Email Id required",
                                    pattern: { 
                                      value: /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z0-9_\-\.]+)\.\2)([A-Za-z0-9_\-\.]+\.)+([A-Za-z]{2,4})$/,
                                      message: "Email Id Invalid",
                                    },
                                  })}
                                  className={classNames("", { "is-invalid": errors?.email })}
                                />
                                {errors.email && <div className="invalid-feedback">{errors.email.message}</div>}
                              </Form.Group>
                            </div>

                            <div className="col-xl-6 col-md-6 col-sm-6 col-12">
                              <Form.Group
                                className="Form-field-holder"
                                controlId="formBasicEmail"
                              >
                                <Form.Label>Enter Contact Number</Form.Label>
                                <Form.Control
                                  className={classNames("", {
                                    "is-invalid": errors?.contact_number,
                                    "is-valid": getValues("contact_number"),
                                  })}
                                  type="text"
                                  name="contact_number"
                                  placeholder="Enter Mobile Number"
                                  {...register("contact_number", {
                                    required: "Contact No is required",
                                    minLength: {
                                      value: 10,
                                      message:
                                        "Number should be at least 10 characters",
                                    },
                                  })}
                                  onKeyDown={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      if (event.key !== "Backspace") {
                                        event.preventDefault();
                                      }
                                    }
                                  }}
                                  maxLength={10}
                                />
                                {errors?.contact_number && (
                                  <sup className="text-danger">
                                    {errors?.contact_number?.message}
                                  </sup>
                                )}
                              </Form.Group>
                            </div>

                            <div className="col-12">
                              <Form.Group
                                className="Form-field-holder"
                                controlId="formBasicEmail"
                              >
                                <Form.Label>Enter Message</Form.Label>
                                <Form.Control
                                  as="textarea"
                                  name="message"
                                  type="text"
                                  rows={3}
                                  placeholder="Enter Message"
                                  {...register("message", {
                                    required: "Message required",
                                  })}
                                  className={classNames("", {
                                    "is-invalid": errors?.message,
                                  })}
                                />
                                {errors.message && <div className="invalid-feedback">{errors.message.message}</div>}
                              </Form.Group>
                            </div>
                          </div>
                        </Form>
                      </div>

                      <div className="btn-holder">
                        <Btn_holder className={"common_btn"} text={"Submit"}  onClick={handleSubmit(onSubmit)}/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Successfull_Modal show={successModal} onHide={() => setSuccessModal(false)} message={"Inquiry Sent Successfully"} />
      </section>
    </>
  );
}

export default Your_Inquiries;
