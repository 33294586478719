import React from "react";
import "./About_team.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";

const About_team = () => {
  const team = [
    {
      image: "/assets/Images/aboutus/team/BAMBAM-SANJAY-CHOUDHARY.png",
      name: "BAMBAM SANJAY CHOUDHARY",
      position: "DIRECTOR",
    },
    {
      image: "/assets/Images/aboutus/team/ABHIJEET-JADHAV.png",
      name: "ABHIJEET JADHAV",
      position: "VICE PRESIDENT",
    },
    {
      image: "/assets/Images/aboutus/team/BHARATI-GODBOLE.png",
      name: "BHARATI GODBOLE",
      position: "ADMINISTRATIVE HEAD",
    },
    {
      image: "/assets/Images/aboutus/team/PUSHPA-BAREDAR.png",
      name: "PUSHPA BAREDAR",
      position: "SR. SALES COORDINATOR",
    },

    {
      image: "/assets/Images/aboutus/team/MANJUSHA-BAGNARE.png",
      name: "MANJUSHA BAGNARE ",
      position: "SR.BILLING EXECUTIVE",
    },
  ];

  return (
    <>
      <section className="About_team_sec">
        <div className="container">
          <div className="heading-holder-section text-center">
            <h2 className="main-heading">Team</h2>
            <h3 className="heading">Meet Our Dedicated Team Of Experts</h3>
          </div>

          <div className="teamdiv">
            <Swiper
              className="myswiper About_team_slider"
              spaceBetween={20}
              slidesPerView={4}
              navigation={true}
              modules={[Navigation]}
              breakpoints={{
                // When the window is >= 320px
                320: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                // When the window is >= 640px
                640: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                // When the window is >= 768px
                768: {
                  slidesPerView: 3,
                  spaceBetween: 15,
                },
                // When the window is >= 1024px
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 15,
                },
              }}
            >
              {team?.map((item, index) => (
                <SwiperSlide key={index}>
                  <div className="teamcard">
                    <div className="main-img-holder">
                      <div className="img-div">
                        <img
                          className="memberimg"
                          src={process.env.PUBLIC_URL + item.image}
                        />
                      </div>
                    </div>
                    <div className="detailsdiv">
                      <div>
                        <h6 className="name">{item.name}</h6>
                        <p className="position">{item.position}</p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
};

export default About_team;
