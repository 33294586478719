import React, { useState } from "react";
import "./OurProfessionalism.css";
import Btn_holder from "../../Common_Component/Btn_holder/Btn_holder";
import { Link } from "react-router-dom";

function OurProfessionalism() {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <>
      <section
        className="OurProfessionalism"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="container">
          <div className="OurProfessionalism_holder">
            <div className="row">
              <div className="col-xl-5 col-md-6 order-lg-1 order-2">
                <div className="content_holder">
                  <div className="heading_holder">
                    <h3 className="heading-text">About Us</h3>
                  </div>

                  <div className="text_holder">
                    <p>
                      Since our inception in 1996, Choudhary scaffolding
                      contractors is a leading turnkey solution provider for all
                      types of
                      {" "}<b>
                        Scaffolding services on Rent & Sales like MS Cup lock
                        along with installation and dismantling, Certified
                        Aluminium trolley, FRP trolley, Airport ladder, A type
                        ladder and scaffolding accessories.
                      </b>{" "}
                    </p>

                    <p>
                      With over decade’s we have continuously strived to
                      maintain the highest possible{" "}
                      <b>“Safety, Service and Commitment”</b>. This is our
                      principal, which makes us to stand upfront in queue for
                      efficient, economic, and leading reliable service
                      providing organization.{" "}
                      <b>
                        The speed of scaffolding erection and dismantling is
                        unique quality of Choudhary scaffolding contractors.
                      </b>
                    </p>

                    <p>
                      We aim to provide key supplier in sale of all types of
                      scaffolding for all industrial, residential and commercial
                      needs. The aluminium and FRP scaffolding products are
                      generation ahead in term of state of art of technology,
                      light-weight, confined spaces, safe & secure working
                      conditions in extended heights indoor as well as outdoor.
                      These are highly versatile towers provide strong working
                      platform for verity of heights along with Ideal speed of
                      installation.
                    </p>

                    <p>
                      The “Choudhary Scaffolding Contractor” enhances the
                      sophisticated interplay of quality and performance in line
                      with building regulation requirement and underpinned by a
                      manufacturing process of meeting the most expecting
                      quality standard in aluminium scaffolding.
                    </p>
                  </div>

                  <div className="btn_holder">
                    <Link to={"/about-us"}>
                      <Btn_holder className={"common_btn"} text={"Know More"} />
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-xl-7 col-md-6 order-md-2  order-1">
                <div className="overlay-img-holder">
                  <div className="row overlay-imgs">
                    <div className="col-xl-9 col-md-8 col-6  overlay-text-img-holder">
                      <div className="img-holder bigger-img-holder">
                        <img
                          className="professional_img"
                          src={
                            isHovered
                              ? process.env.PUBLIC_URL +
                                "/assets/Images/Home/OurProfessionalism/small-img.png"
                              : process.env.PUBLIC_URL +
                                "/assets/Images/Home/OurProfessionalism/big-img.png"
                          }
                          alt="Logo"
                        />
                      </div>

                      <div className="vertical-text-holder">
                        <h4>Our Professionalism</h4>
                      </div>
                    </div>

                    <div className="col-xl-5 col-md-2 col-3 smaller-img-holder">
                      <div className="img-holder ">
                        <img
                          className="professional_img"
                          src={
                            isHovered
                              ? process.env.PUBLIC_URL +
                                "/assets/Images/Home/OurProfessionalism/big-img.png"
                              : process.env.PUBLIC_URL +
                                "/assets/Images/Home/OurProfessionalism/small-img.png"
                          }
                          alt="Logo"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-heading-holder">
            <h3>Our Professionalism</h3>
          </div>
        </div>
      </section>
    </>
  );
}

export default OurProfessionalism;
