import React, { useState, useEffect, useContext } from "react";
import './About_whyschooseus.css'
import { Context } from "../../../utils/context";
const About_whyschooseus = ({data}) => {
    const {  IMG_URL } = useContext(Context);
   console.log(data,"data ddddddddddddddddddd");
   
    return (
        <>
            <section className='About_whyschooseus'>
                <div className='container'>
                    <div className="heading-holder-section text-center">
                        <h2 className="main-heading">Why Choose Us</h2>
                        <h3 className="heading">Excellence in Every Step</h3>
                    </div>

                    <div className='choosecont'>
                        <div className='row'>
                            {data?.map((val)=>
                            <div className='col-lg-4 col-sm-6 mb-4'>
                                <div className='visioncard'>
                                    <div className='d-flex mb-3'>
                                        <div className='icondiv'>
                                            <img className='icon' src={IMG_URL + val?.image} />
                                        </div>
                                        <div className='namediv my-auto'>
                                            <h6 className='name'>{val?.title}</h6>
                                        </div>
                                    </div>
                                    <p className='content'>{val?.description}</p>
                                </div> 
                           
                            </div> )}
                            {/* <div className='col-lg-4 col-sm-6 mb-4'>
                                <div className='visioncard'>
                                    <div className='d-flex mb-3'>
                                        <div className='icondiv'>
                                            <img className='icon' src={process.env.PUBLIC_URL + '/assets/Images/aboutus/why-choose/targeting.png'} />
                                        </div>
                                        <div className='namediv my-auto'>
                                            <h6 className='name'>Mission</h6>
                                        </div>
                                    </div>
                                    <p className='content'>Our mission is to provide top-tier scaffolding services with a focus on safety, efficiency, & customer satisfaction, ensuring successful construction projects through expert workmanship and strong partnerships</p>
                                </div> 
                            </div>
                            <div className='col-lg-4 col-sm-6 mb-4'>
                                <div className='visioncard'>
                                    <div className='d-flex mb-3'>
                                        <div className='icondiv'>
                                            <img className='icon' src={process.env.PUBLIC_URL + '/assets/Images/aboutus/why-choose/diamond.png'} />
                                        </div>
                                        <div className='namediv my-auto'>
                                            <h6 className='name'>Values</h6>
                                        </div>
                                    </div>
                                    <p className='content'>We ensure top safety standards, act with integrity, and deliver high-quality scaffolding. Reliable and punctual, we meet our commitments and embrace innovation. Our customer-focused approach and teamwork build strong client relationships.</p>
                                </div> 
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default About_whyschooseus