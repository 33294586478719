import React from "react";
import "./Scaffolding_on_Rent_content.css";
import { Nav, Tab } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Cup_Lock_Services_slider_section from "../../../Common_Component/Cup_Lock_Services_slider_section/Cup_Lock_Services_slider_section";

const Scaffolding_on_Rent_content = () => {
  return (
    <>
      <section className="Scaffolding_on_Rent_content_holder common-section ">
        <div className="container">
          <div className="Scaffolding_on_Rent_content">
            <div className="tabs-holder">
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey="NARROW_WIDTH"
              >
                <div className="row">
                  {/* <div className="col-md-12">
                    <Nav variant="pills" className="tabs-container_holder">
                      <Nav.Item>
                        <Nav.Link eventKey="NARROW_WIDTH">
                          NARROW-WIDTH
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="WIDE_WIDTH">WIDE WIDTH</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div> */}
                  <div className="col-md-12">
                    <Tab.Content className="outer-tab-content">
                      <Tab.Pane eventKey="NARROW_WIDTH">
                        <div className="NARROW_WIDTH_Contant">
                          <div className="row">
                            <div className="col-md-5">
                              <Cup_Lock_Services_slider_section />
                            </div>

                            <div className="col-md-7">
                              <div className="tab-content-holder">
                                {/* <h3>NARROW WIDTH ALUMINIUM SCAFFOLDING</h3> */}
                                <p>
                                  Cup-Lock Scaffolding is a temporary means of
                                  support used by most trades in building and
                                  other construction industry and for
                                  maintenance and services jobs in operating
                                  plants as
                                </p>

                                <p>
                                  All you expect from scaffolding material on
                                  rent is strength and reliability. It’s the
                                  support system for construction activities.
                                  Therefore, we keep on researching to make it
                                  stronger and convenient to use. At the same
                                  time, there are specific requirements for
                                  specific jobs. Therefore, we have developed an
                                  inventory of many options on our list.
                                </p>

                                <p>
                                  Cup-Lock scaffolding system is one of the most
                                  important locking systems in modern-day
                                  scaffolding. It has a unique mechanism that
                                  makes it easy to use. May it be construction,
                                  demolition, or a maintenance project, the
                                  Cup-Lock framework system is a preferred
                                  choice.
                                </p>

                                <p>
                                  It’s a Single node point action of unique
                                  locking that makes the Cup-Lock system on hire
                                  a fast, versatile and optimized system of
                                  scaffolding. Cup-Lock system scaffolding
                                  design is one of the most popular joint lock
                                  systems throughout the world
                                </p>
                              </div>

                              <hr />

                              <div className="inner-tabs-holder">
                                {/* <Tab.Container
                                  id="left-tabs-example"
                                  defaultActiveKey="Technical_Specification"
                                >
                                  <div className="row">
                                    <div className="col-md-12">
                                      <Nav
                                        variant="pills"
                                        className="inner-tabs-container_holder"
                                      >
                                        <Nav.Item>
                                          <Nav.Link eventKey="Technical_Specification">
                                            Technical Specification
                                          </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                          <Nav.Link eventKey="Advantages">
                                            Advantages
                                          </Nav.Link>
                                        </Nav.Item>
                                      </Nav>
                                    </div>
                                    <div className="col-md-12">
                                      <Tab.Content className="inner-tab-content">
                                        <Tab.Pane eventKey="Technical_Specification">
                                          <div className="NARROW_WIDTH_Contant">
                                            <div className="inner-tab-content-holder">
                                              <div className="main-table-holder">
                                                <div className="inner-tab-heading">
                                                  <h3>
                                                    PLATFORM SPECIFICATION:
                                                  </h3>
                                                </div>
                                                <div className="table-heading-holder">
                                                  <h3>
                                                    FILM FACE ANTI-SKID PLYWOOD
                                                  </h3>
                                                </div>
                                                <div className="table-data-holder">
                                                  <Table
                                                    responsive
                                                    className="record-table-holder"
                                                  >
                                                    <thead>
                                                      <tr>
                                                        <th>Sr.</th>
                                                        <th>
                                                          Physical Property
                                                        </th>
                                                        <th>Unit</th>
                                                        <th>Value</th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      <tr>
                                                        <td>01.</td>
                                                        <td>Density</td>
                                                        <td>Gm/Cm3</td>
                                                        <td>0.95</td>
                                                      </tr>
                                                      <tr>
                                                        <td>02.</td>
                                                        <td>
                                                          Boiling Water Proof
                                                        </td>
                                                        <td>
                                                          (Immersion 72Hrs @100
                                                          (1C)
                                                        </td>
                                                        <td>Passed</td>
                                                      </tr>
                                                      <tr>
                                                        <td>03.</td>
                                                        <td>
                                                          Moisture Content
                                                          &Volatile Content
                                                        </td>
                                                        <td></td>
                                                        <td>6.82%</td>
                                                      </tr>

                                                      <tr>
                                                        <th>Sr.</th>
                                                        <th>
                                                          Mechanical Property
                                                        </th>
                                                        <th>Unit</th>
                                                        <th>Value</th>
                                                      </tr>

                                                      <tr>
                                                        <td>01.</td>
                                                        <td>
                                                          Tensile Strength Along
                                                          Grain
                                                        </td>
                                                        <td>Mpa</td>
                                                        <td>82</td>
                                                      </tr>
                                                      <tr>
                                                        <td>02.</td>
                                                        <td>
                                                          Cross-Breaking
                                                          Strength
                                                        </td>
                                                        <td>Mpa</td>
                                                        <td>132</td>
                                                      </tr>
                                                      <tr>
                                                        <td>03.</td>
                                                        <td>
                                                          Compressive Strength{" "}
                                                        </td>
                                                        <td>Mpa</td>
                                                        <td>158</td>
                                                      </tr>
                                                    </tbody>
                                                  </Table>
                                                </div>
                                              </div>

                                              <div className="main-table-holder">
                                                <div className="inner-tab-heading">
                                                  <h3>
                                                    MATERIAL CHEMICAL
                                                    COMPOSITION (IN %):
                                                  </h3>
                                                </div>

                                                <div className="table-data-holder">
                                                  <Table
                                                    responsive
                                                    className="record-table-holder"
                                                  >
                                                    <thead>
                                                      <tr>
                                                        <th>Mg</th>
                                                        <th>Si</th>
                                                        <th>Mn</th>
                                                        <th>Fe</th>
                                                        <th>Cu</th>
                                                        <th>Zn</th>
                                                        <th>V</th>
                                                        <th>Ti</th>
                                                        <th>AI</th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      <tr>
                                                        <td>0.54</td>
                                                        <td>0.6</td>
                                                        <td></td>
                                                        <td>0.26</td>
                                                        <td>0.08</td>
                                                        <td>0.02</td>
                                                        <td>0.01</td>
                                                        <td>0.0178</td>
                                                        <td>98.41</td>
                                                      </tr>
                                                    </tbody>
                                                  </Table>
                                                </div>
                                              </div>

                                              <div className="main-table-holder">
                                                <div className="inner-tab-heading">
                                                  <h3>LOAD TEST:</h3>
                                                </div>

                                                <div className="description-holder">
                                                  <p>
                                                    Certified form
                                                    BEPL/2018-19/IS/M151 from
                                                    Government competent
                                                    authority for safe working
                                                    load - 250 kg.
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="Advantages">
                                          <div className="Advantages-list-holder">
                                            <h3 className="inner-heading-text">
                                              Key Features of Cup Lock
                                              Scaffolding:
                                            </h3>
                                            <ul>
                                              <li>
                                                It’s Unique And Simpler Type Of
                                                Aluminium Scaffolding
                                              </li>

                                              <li>
                                                A Very Few Material With Light
                                                Weight Gives Ideal Speed Of
                                                Installation.
                                              </li>
                                              <li>
                                                Standard Width Gives Both Indoor
                                                And Outdoor Working.
                                              </li>

                                              <li>
                                                Guardrails Frames Work As
                                                Safeguard.
                                              </li>

                                              <li>
                                                Toe- Board Will Work As Fall
                                                Arrester Of Small Parts Like
                                                Tools Form Scaffolding
                                              </li>
                                            </ul>
                                          </div>
                                        </Tab.Pane>
                                      </Tab.Content>
                                    </div>
                                  </div>
                                </Tab.Container> */}

                                <div className="Advantages-list-holder">
                                  <h3 className="inner-heading-text">
                                    Key Features of Cup Lock Scaffolding:
                                  </h3>
                                  <ul>
                                    <li>
                                      Offers greater versatility and
                                      adaptability to different project
                                      requirements.
                                    </li>

                                    <li>
                                      Utilizes a cup and blade locking mechanism
                                      for quick and secure assembly.
                                    </li>
                                    <li>
                                      Well-suited for both vertical and
                                      horizontal load-bearing applications.
                                    </li>

                                    <li>
                                      Efficient for complex and irregular
                                      structures.
                                    </li>

                                    <li>
                                      Its ideal speed of installation and
                                      dismantling.
                                    </li>
                                  </ul>
                                </div>

                                <hr />

                                <div className="Advantages-list-holder">
                                  <h3 className="inner-heading-text">
                                    Application of Cup Lock Scaffolding:
                                  </h3>
                                  <ul>
                                    <li>
                                      Construction: Cup-Lock scaffolding is used
                                      in the construction of high-rise
                                      buildings, bridges, and commercial
                                      structures.
                                    </li>

                                    <li>
                                      Event structures: Cup-Lock scaffolding is
                                      used to construct temporary event
                                      structures, such as stages and grandstands
                                    </li>
                                    <li>
                                      Working at heightened elevations or
                                      elevators installation: Cup-Lock
                                      scaffolding is used for working at
                                      heightened elevations
                                    </li>

                                    <li>
                                      Maintenance and refurbishment in
                                      industrial sector: Cup-Lock scaffolding
                                      can be used to maintain and refurbish
                                      existing buildings and structures
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab.Pane>
                      {/* <Tab.Pane eventKey="WIDE_WIDTH">
                        <div className="NARROW_WIDTH_Contant">
                          <div className="row">
                            <div className="col-md-5">
                              <Wide_width_Services_slider_section />
                            </div>

                            <div className="col-md-7">
                              <div className="tab-content-holder">
                                <h3>WIDE WIDTH ALUMINIUM SCAFFOLDING</h3>
                                <p>
                                  The Wide width aluminium scaffolding gives
                                  wider space to work two personal along with
                                  tools and tackles. Single scaffolding with
                                  various height availability in range of 280 mm
                                  by shifting the platform. The wider width of
                                  1.350m provides more strength & easy movement.
                                </p>
                              </div>

                              <div className="inner-tabs-holder">
                                <Tab.Container
                                  id="left-tabs-example"
                                  defaultActiveKey="Technical_Specification"
                                >
                                  <div className="row">
                                    <div className="col-md-12">
                                      <Nav
                                        variant="pills"
                                        className="inner-tabs-container_holder"
                                      >
                                        <Nav.Item>
                                          <Nav.Link eventKey="Technical_Specification">
                                            Technical Specification
                                          </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                          <Nav.Link eventKey="Advantages">
                                            Advantages
                                          </Nav.Link>
                                        </Nav.Item>
                                      </Nav>
                                    </div>
                                    <div className="col-md-12">
                                      <Tab.Content className="inner-tab-content">
                                        <Tab.Pane eventKey="Technical_Specification">
                                          <div className="NARROW_WIDTH_Contant">
                                            <div className="inner-tab-content-holder">
                                              <div className="main-table-holder">
                                                <div className="inner-tab-heading">
                                                  <h3>
                                                    PLATFORM SPECIFICATION:
                                                  </h3>
                                                </div>
                                                <div className="table-heading-holder">
                                                  <h3>
                                                    FILM FACE ANTI-SKID PLYWOOD
                                                  </h3>
                                                </div>
                                                <div className="table-data-holder">
                                                  <Table
                                                    responsive
                                                    className="record-table-holder"
                                                  >
                                                    <thead>
                                                      <tr>
                                                        <th>Sr.</th>
                                                        <th>
                                                          Physical Property
                                                        </th>
                                                        <th>Unit</th>
                                                        <th>Value</th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      <tr>
                                                        <td>01.</td>
                                                        <td>Density</td>
                                                        <td>Gm/Cm3</td>
                                                        <td>0.95</td>
                                                      </tr>
                                                      <tr>
                                                        <td>02.</td>
                                                        <td>
                                                          Boiling Water Proof
                                                        </td>
                                                        <td>
                                                          (Immersion 72Hrs @100
                                                          (1C)
                                                        </td>
                                                        <td>Passed</td>
                                                      </tr>
                                                      <tr>
                                                        <td>03.</td>
                                                        <td>
                                                          Moisture Content
                                                          &Volatile Content
                                                        </td>
                                                        <td></td>
                                                        <td>6.82%</td>
                                                      </tr>

                                                      <tr>
                                                        <th>Sr.</th>
                                                        <th>
                                                          Mechanical Property
                                                        </th>
                                                        <th>Unit</th>
                                                        <th>Value</th>
                                                      </tr>

                                                      <tr>
                                                        <td>01.</td>
                                                        <td>
                                                          Tensile Strength Along
                                                          Grain
                                                        </td>
                                                        <td>Mpa</td>
                                                        <td>82</td>
                                                      </tr>
                                                      <tr>
                                                        <td>02.</td>
                                                        <td>
                                                          Cross-Breaking
                                                          Strength
                                                        </td>
                                                        <td>Mpa</td>
                                                        <td>132</td>
                                                      </tr>
                                                      <tr>
                                                        <td>03.</td>
                                                        <td>
                                                          Compressive Strength{" "}
                                                        </td>
                                                        <td>Mpa</td>
                                                        <td>158</td>
                                                      </tr>
                                                    </tbody>
                                                  </Table>
                                                </div>
                                              </div>

                                              <div className="main-table-holder">
                                                <div className="inner-tab-heading">
                                                  <h3>
                                                    MATERIAL CHEMICAL
                                                    COMPOSITION (IN %):
                                                  </h3>
                                                </div>

                                                <div className="table-data-holder">
                                                  <Table
                                                    responsive
                                                    className="record-table-holder"
                                                  >
                                                    <thead>
                                                      <tr>
                                                        <th>Mg</th>
                                                        <th>Si</th>
                                                        <th>Mn</th>
                                                        <th>Fe</th>
                                                        <th>Cu</th>
                                                        <th>Zn</th>
                                                        <th>V</th>
                                                        <th>Ti</th>
                                                        <th>AI</th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      <tr>
                                                        <td>0.54</td>
                                                        <td>0.6</td>
                                                        <td></td>
                                                        <td>0.26</td>
                                                        <td>0.08</td>
                                                        <td>0.02</td>
                                                        <td>0.01</td>
                                                        <td>0.0178</td>
                                                        <td>98.41</td>
                                                      </tr>
                                                    </tbody>
                                                  </Table>
                                                </div>
                                              </div>

                                              <div className="main-table-holder">
                                                <div className="inner-tab-heading">
                                                  <h3>LOAD TEST:</h3>
                                                </div>

                                                <div className="description-holder">
                                                  <p>
                                                    Certified form
                                                    BEPL/2018-19/IS/M151 from
                                                    Government competent
                                                    authority for safe working
                                                    load - 250 kg.
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="Advantages">
                                          <div className="Advantages-list-holder">
                                            <ul>
                                              <li>
                                                It’s Unique And Simpler Type Of
                                                Aluminium Scaffolding
                                              </li>

                                              <li>
                                                A Very Few Material With Light
                                                Weight Gives Ideal Speed Of
                                                Installation. Standard Width
                                                Gives Both Indoor And Outdoor
                                                Working. Guardrails Frames Work
                                                As Safeguard. Toe- Board Will
                                                Work As Fall Arrester Of Small
                                                Parts Like Tools Form
                                                Scaffolding
                                              </li>

                                              <li>
                                                It’s Unique And Simpler Type Of
                                                Aluminium Scaffolding A Very Few
                                                Material With Light Weight Gives
                                                Ideal Speed Of Installation.
                                              </li>

                                              <li>
                                                Standard Width Gives Both Indoor
                                                And Outdoor Working.
                                              </li>

                                              <li>
                                                Guardrails Frames Work As
                                                Safeguard.
                                              </li>

                                              <li>
                                                Toe- Board Will Work As Fall
                                                Arrester Of Small Parts Like
                                                Tools Form Scaffolding
                                              </li>
                                            </ul>
                                          </div>
                                        </Tab.Pane>
                                      </Tab.Content>
                                    </div>
                                  </div>
                                </Tab.Container>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab.Pane> */}
                    </Tab.Content>
                  </div>
                </div>
              </Tab.Container>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Scaffolding_on_Rent_content;
