import React from "react";
import "./Aluminium_Scaffolding_content.css";
import { Nav, Tab } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import FRP_Services_slider_section from "../../../Common_Component/FRP_Services_slider_section/FRP_Services_slider_section";
import Wide_width_Services_slider_section from "../../../Common_Component/Wide_width_Services_slider_section/Wide_width_Services_slider_section";
import Narrow_width_Services_slider_section from "../../../Common_Component/Narrow_width_Services_slider_section/Narrow_width_Services_slider_section";

const Aluminium_Scaffolding_content = () => {
  return (
    <>
      <section className="Scaffolding_on_Rent_content_holder common-section ">
        <div className="container">
          <div className="Scaffolding_on_Rent_content">
            <div className="tabs-holder">
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey="NARROW_WIDTH"
              >
                <div className="row">
                  <div className="col-md-12">
                    <Nav variant="pills" className="tabs-container_holder">
                      <Nav.Item>
                        <Nav.Link eventKey="NARROW_WIDTH">
                          NARROW WIDTH
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="WIDE_WIDTH">WIDE WIDTH</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                  <div className="col-md-12">
                    <Tab.Content className="outer-tab-content">
                      <Tab.Pane eventKey="NARROW_WIDTH">
                        <div className="NARROW_WIDTH_Contant">
                          <div className="row">
                            <div className="col-md-5">
                              <Narrow_width_Services_slider_section />
                            </div>

                            <div className="col-md-7">
                              <div className="tab-content-holder">
                                <h3>NARROW WIDTH ALUMINIUM SCAFFOLDING</h3>
                                <p>
                                  The Narrow width aluminium scaffolding is an
                                  extremely versatile work platform built to
                                  suit all trades with low reach requirements
                                  including electricians, interior installers,
                                  plasters and sign writers. Its standard width
                                  of 0.750 meter will act as a trolley for
                                  moving equipment or a workbench on work sites.
                                </p>
                              </div>

                              <hr />

                              <div className="tab-content-holder">
                                <h3>Dimensions of working Platforms</h3>
                                <p>
                                  <b>Note:</b> The product code very as per
                                  length. Ex. If you require working height of
                                  3.8m and length 1.8m then product code will be
                                  CSI-PM-1.8.
                                </p>
                              </div>

                              <hr />

                              <div className="inner-tabs-holder">
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="inner-tab-content">
                                      <div className="NARROW_WIDTH_Contant">
                                        <div className="inner-tab-content-holder">
                                          <div className="main-table-holder">
                                            <div className="tab-content-holder">
                                              <h3>Technical Specification:</h3>
                                            </div>

                                            <div className="inner-tab-heading">
                                              <h3>PLATFORM SPECIFICATION:</h3>
                                            </div>
                                            <div className="table-heading-holder">
                                              <h3>Platform specification</h3>
                                            </div>

                                            <div className="table-heading-holder">
                                              <h3>
                                                FILM FACE ANTI-SKID PLYWOOD
                                              </h3>
                                            </div>
                                            <div className="table-data-holder">
                                              <Table
                                                responsive
                                                className="record-table-holder"
                                              >
                                                <thead>
                                                  <tr>
                                                    <th>Sr.</th>
                                                    <th>Physical Property</th>
                                                    <th>Unit</th>
                                                    <th>Value</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr>
                                                    <td>01.</td>
                                                    <td>Density</td>
                                                    <td>Gm/Cm3</td>
                                                    <td>0.95</td>
                                                  </tr>
                                                  <tr>
                                                    <td>02.</td>
                                                    <td>Boiling Water Proof</td>
                                                    <td>
                                                      (Immersion 72Hrs @100 (1C)
                                                    </td>
                                                    <td>Passed</td>
                                                  </tr>
                                                  <tr>
                                                    <td>03.</td>
                                                    <td>
                                                      Moisture Content &Volatile
                                                      Content
                                                    </td>
                                                    <td></td>
                                                    <td>6.82%</td>
                                                  </tr>

                                                  <tr>
                                                    <th>Sr.</th>
                                                    <th>Mechanical Property</th>
                                                    <th>Unit</th>
                                                    <th>Value</th>
                                                  </tr>

                                                  <tr>
                                                    <td>01.</td>
                                                    <td>
                                                      Tensile Strength Along
                                                      Grain
                                                    </td>
                                                    <td>Mpa</td>
                                                    <td>82</td>
                                                  </tr>
                                                  <tr>
                                                    <td>02.</td>
                                                    <td>
                                                      Cross-Breaking Strength
                                                    </td>
                                                    <td>Mpa</td>
                                                    <td>132</td>
                                                  </tr>
                                                  <tr>
                                                    <td>03.</td>
                                                    <td>
                                                      Compressive Strength{" "}
                                                    </td>
                                                    <td>Mpa</td>
                                                    <td>158</td>
                                                  </tr>
                                                </tbody>
                                              </Table>
                                            </div>
                                          </div>

                                          <div className="main-table-holder">
                                            <div className="inner-tab-heading">
                                              <h3>
                                                MATERIAL CHEMICAL COMPOSITION
                                                (IN %):
                                              </h3>
                                            </div>

                                            <div className="table-data-holder">
                                              <Table
                                                responsive
                                                className="record-table-holder"
                                              >
                                                <thead>
                                                  <tr>
                                                    <th>Mg</th>
                                                    <th>Si</th>
                                                    <th>Mn</th>
                                                    <th>Fe</th>
                                                    <th>Cu</th>
                                                    <th>Zn</th>
                                                    <th>V</th>
                                                    <th>Ti</th>
                                                    <th>AI</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr>
                                                    <td>0.54</td>
                                                    <td>0.6</td>
                                                    <td>0.0427</td>
                                                    <td>0.26</td>
                                                    <td>0.08</td>
                                                    <td>0.02</td>
                                                    <td>0.01</td>
                                                    <td>0.0178</td>
                                                    <td>98.41</td>
                                                  </tr>
                                                </tbody>
                                              </Table>
                                            </div>
                                          </div>

                                          <div className="main-table-holder">
                                            <div className="table-data-holder">
                                              <Table
                                                responsive
                                                className="record-table-holder"
                                              >
                                                <thead>
                                                  <tr>
                                                    <th>
                                                      Product code (As per
                                                      length)
                                                    </th>
                                                    <th>CSI-PM-1.8/2/2.5</th>
                                                    <th>CSI-QM-1.8/2/2.5</th>
                                                    <th>CSI-RM-1.8/2/2.5</th>
                                                    <th>CSI-SM-1.8/2/2.5</th>
                                                    <th>CSI-TM-1.8/2/2.5</th>
                                                    <th>CSI-UM-1.8/2/2.5</th>
                                                    <th>CSI-VM-1.8/2/2.5</th>
                                                    <th>CSI-WM-1.8/2/2.5</th>
                                                    <th>CSI-XM-1.8/2/2.5</th>
                                                    <th>CSI-YM-1.8/2/2.5</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr>
                                                    <td>Working Height</td>
                                                    <td>3.8</td>
                                                    <td>4.2</td>
                                                    <td>5.8</td>
                                                    <td>6.8</td>
                                                    <td>7.8</td>
                                                    <td>8.8</td>
                                                    <td>9.8</td>
                                                    <td>10.8</td>
                                                    <td>11.8</td>
                                                    <td>12.8</td>
                                                  </tr>
                                                  <tr>
                                                    <td>Scaffold Height</td>
                                                    <td>2.8</td>
                                                    <td>3.2</td>
                                                    <td>4.3</td>
                                                    <td>5.3</td>
                                                    <td>6.3</td>
                                                    <td>7.3</td>
                                                    <td>8.3</td>
                                                    <td>9.3</td>
                                                    <td>10.3</td>
                                                    <td>11.3</td>
                                                  </tr>
                                                  <tr>
                                                    <td>Platform Height</td>
                                                    <td>1.8</td>
                                                    <td>2.2</td>
                                                    <td>3.3</td>
                                                    <td>4.3</td>
                                                    <td>5.3</td>
                                                    <td>6.3</td>
                                                    <td>7.3</td>
                                                    <td>8.3</td>
                                                    <td>9.3</td>
                                                    <td>10.3</td>
                                                  </tr>

                                                  <tr>
                                                    <td rowSpan={3}>Length</td>
                                                    <td>1.8</td>
                                                    <td>1.8</td>
                                                    <td>1.8</td>
                                                    <td>1.8</td>
                                                    <td>1.8</td>
                                                    <td>1.8</td>
                                                    <td>1.8</td>
                                                    <td>1.8</td>
                                                    <td>1.8</td>
                                                    <td>1.8</td>
                                                  </tr>

                                                  <tr>
                                                    <td>2</td>
                                                    <td>2</td>
                                                    <td>2</td>
                                                    <td>2</td>
                                                    <td>2</td>
                                                    <td>2</td>
                                                    <td>2</td>
                                                    <td>2</td>
                                                    <td>2</td>
                                                    <td>2</td>
                                                  </tr>

                                                  <tr>
                                                    <td>2.5</td>
                                                    <td>2.5</td>
                                                    <td>2.5</td>
                                                    <td>2.5</td>
                                                    <td>2.5</td>
                                                    <td>2.5</td>
                                                    <td>2.5</td>
                                                    <td>2.5</td>
                                                    <td>2.5</td>
                                                    <td>2.5</td>
                                                  </tr>
                                                </tbody>
                                              </Table>
                                            </div>
                                          </div>

                                          <div className="main-table-holder">
                                            <div className="inner-tab-heading">
                                              <h3>LOAD TEST:</h3>
                                            </div>

                                            <div className="description-holder">
                                              <p>
                                                Certified form
                                                BEPL/2018-19/IS/M151 from
                                                Government competent authority
                                                for safe working load - 250 kg.
                                              </p>
                                            </div>
                                          </div>

                                          <div className="Advantages-list-holder">
                                            <h3 className="inner-heading-text">
                                              Advantages:
                                            </h3>
                                            <ul>
                                              <li>
                                                It’s unique and simpler type of
                                                aluminium scaffolding
                                              </li>

                                              <li>
                                                A very few material with light
                                                weight gives Ideal speed of
                                                installation.
                                              </li>
                                              <li>
                                                Standard width gives both indoor
                                                and outdoor working.
                                              </li>

                                              <li>
                                                Guardrails frames work as
                                                safeguard.
                                              </li>

                                              <li>
                                                Toe- Board will work as fall
                                                arrester of small parts like
                                                tools form scaffolding
                                              </li>
                                            </ul>
                                          </div>

                                          <div className="main-table-holder">
                                            <div className="inner-tab-heading">
                                              <h3>Note:</h3>
                                            </div>

                                            <div className="description-holder">
                                              <p>
                                                We provide door to door
                                                replacement policy of parts at
                                                very nominal cost
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="WIDE_WIDTH">
                        <div className="NARROW_WIDTH_Contant">
                          <div className="row">
                            <div className="col-md-5">
                              <Wide_width_Services_slider_section />
                            </div>

                            <div className="col-md-7">
                              <div className="tab-content-holder">
                                <h3>
                                  WIDE WIDTH WITH STAIRWAY ALUMINIUM SCAFFOLDING
                                </h3>
                                <p>
                                  The Wide width with Stairway aluminium
                                  scaffolding gives easy ascent and descent
                                  activity in working. Platform level can
                                  adjustable in the intervals of 280/300mm for
                                  safe working as per height requirement.
                                </p>
                              </div>

                              <div className="tab-content-holder">
                                <h3>Dimensions of working Platforms</h3>
                                <p>
                                  <b>Note:</b> The product code very as per
                                  length. Ex for single height working height
                                  5.3m and 2m length than product code will be
                                  CSI-RS-2
                                </p>
                              </div>

                              <hr />

                              <div className="inner-tabs-holder">
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="inner-tab-content">
                                      <div className="NARROW_WIDTH_Contant">
                                        <div className="inner-tab-content-holder">
                                          <div className="main-table-holder">
                                            <div className="table-data-holder">
                                              <Table
                                                responsive
                                                className="record-table-holder"
                                              >
                                                <thead>
                                                  <tr>
                                                    <th>
                                                      Product code (As per
                                                      length)
                                                    </th>
                                                    <th>CSI-PM-1.8/2/2.5</th>
                                                    <th>CSI-QM-1.8/2/2.5</th>
                                                    <th>CSI-RM-1.8/2/2.5</th>
                                                    <th>CSI-SM-1.8/2/2.5</th>
                                                    <th>CSI-TM-1.8/2/2.5</th>
                                                    <th>CSI-UM-1.8/2/2.5</th>
                                                    <th>CSI-VM-1.8/2/2.5</th>
                                                    <th>CSI-WM-1.8/2/2.5</th>
                                                    <th>CSI-XM-1.8/2/2.5</th>
                                                    <th>CSI-YM-1.8/2/2.5</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr>
                                                    <td>Working Height</td>
                                                    <td>3.8</td>
                                                    <td>4.2</td>
                                                    <td>5.8</td>
                                                    <td>6.8</td>
                                                    <td>7.8</td>
                                                    <td>8.8</td>
                                                    <td>9.8</td>
                                                    <td>10.8</td>
                                                    <td>11.8</td>
                                                    <td>12.8</td>
                                                  </tr>
                                                  <tr>
                                                    <td>Scaffold Height</td>
                                                    <td>2.8</td>
                                                    <td>3.2</td>
                                                    <td>4.3</td>
                                                    <td>5.3</td>
                                                    <td>6.3</td>
                                                    <td>7.3</td>
                                                    <td>8.3</td>
                                                    <td>9.3</td>
                                                    <td>10.3</td>
                                                    <td>11.3</td>
                                                  </tr>
                                                  <tr>
                                                    <td>Platform Height</td>
                                                    <td>1.8</td>
                                                    <td>2.2</td>
                                                    <td>3.3</td>
                                                    <td>4.3</td>
                                                    <td>5.3</td>
                                                    <td>6.3</td>
                                                    <td>7.3</td>
                                                    <td>8.3</td>
                                                    <td>9.3</td>
                                                    <td>10.3</td>
                                                  </tr>

                                                  <tr>
                                                    <td rowSpan={3}>Length</td>
                                                    <td>1.8</td>
                                                    <td>1.8</td>
                                                    <td>1.8</td>
                                                    <td>1.8</td>
                                                    <td>1.8</td>
                                                    <td>1.8</td>
                                                    <td>1.8</td>
                                                    <td>1.8</td>
                                                    <td>1.8</td>
                                                    <td>1.8</td>
                                                  </tr>

                                                  <tr>
                                                    <td>2</td>
                                                    <td>2</td>
                                                    <td>2</td>
                                                    <td>2</td>
                                                    <td>2</td>
                                                    <td>2</td>
                                                    <td>2</td>
                                                    <td>2</td>
                                                    <td>2</td>
                                                    <td>2</td>
                                                  </tr>

                                                  <tr>
                                                    <td>2.5</td>
                                                    <td>2.5</td>
                                                    <td>2.5</td>
                                                    <td>2.5</td>
                                                    <td>2.5</td>
                                                    <td>2.5</td>
                                                    <td>2.5</td>
                                                    <td>2.5</td>
                                                    <td>2.5</td>
                                                    <td>2.5</td>
                                                  </tr>
                                                </tbody>
                                              </Table>
                                            </div>
                                          </div>

                                          <div className="main-table-holder">
                                            <div className="tab-content-holder">
                                              <h3>
                                                Technical Specification of
                                                Platform:
                                              </h3>
                                            </div>

                                            <div className="table-heading-holder">
                                              <h3>Platform specification</h3>
                                            </div>

                                            <div className="table-heading-holder">
                                              <h3>
                                                FILM FACE ANTI-SKID PLYWOOD
                                              </h3>
                                            </div>
                                            <div className="table-data-holder">
                                              <Table
                                                responsive
                                                className="record-table-holder"
                                              >
                                                <thead>
                                                  <tr>
                                                    <th>Sr.</th>
                                                    <th>Physical Property</th>
                                                    <th>Unit</th>
                                                    <th>Value</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr>
                                                    <td>01.</td>
                                                    <td>Density</td>
                                                    <td>Gm/Cm3</td>
                                                    <td>0.95</td>
                                                  </tr>
                                                  <tr>
                                                    <td>02.</td>
                                                    <td>Boiling Water Proof</td>
                                                    <td>
                                                      (Immersion 72Hrs @100 (1C)
                                                    </td>
                                                    <td>Passed</td>
                                                  </tr>
                                                  <tr>
                                                    <td>03.</td>
                                                    <td>
                                                      Moisture Content &Volatile
                                                      Content
                                                    </td>
                                                    <td></td>
                                                    <td>6.82%</td>
                                                  </tr>

                                                  <tr>
                                                    <th>Sr.</th>
                                                    <th>Mechanical Property</th>
                                                    <th>Unit</th>
                                                    <th>Value</th>
                                                  </tr>

                                                  <tr>
                                                    <td>01.</td>
                                                    <td>
                                                      Tensile Strength Along
                                                      Grain
                                                    </td>
                                                    <td>Mpa</td>
                                                    <td>82</td>
                                                  </tr>
                                                  <tr>
                                                    <td>02.</td>
                                                    <td>
                                                      Cross-Breaking Strength
                                                    </td>
                                                    <td>Mpa</td>
                                                    <td>132</td>
                                                  </tr>
                                                  <tr>
                                                    <td>03.</td>
                                                    <td>
                                                      Compressive Strength{" "}
                                                    </td>
                                                    <td>Mpa</td>
                                                    <td>158</td>
                                                  </tr>
                                                </tbody>
                                              </Table>
                                            </div>
                                          </div>

                                          <div className="main-table-holder">
                                            <div className="inner-tab-heading">
                                              <h3>
                                                MATERIAL CHEMICAL COMPOSITION
                                                (IN %):
                                              </h3>
                                            </div>

                                            <div className="table-data-holder">
                                              <Table
                                                responsive
                                                className="record-table-holder"
                                              >
                                                <thead>
                                                  <tr>
                                                    <th>Mg</th>
                                                    <th>Si</th>
                                                    <th>Mn</th>
                                                    <th>Fe</th>
                                                    <th>Cu</th>
                                                    <th>Zn</th>
                                                    <th>V</th>
                                                    <th>Ti</th>
                                                    <th>AI</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr>
                                                    <td>0.54</td>
                                                    <td>0.6</td>
                                                    <td>0.0427</td>
                                                    <td>0.26</td>
                                                    <td>0.08</td>
                                                    <td>0.02</td>
                                                    <td>0.01</td>
                                                    <td>0.0178</td>
                                                    <td>98.41</td>
                                                  </tr>
                                                </tbody>
                                              </Table>
                                            </div>
                                          </div>

                                          <div className="main-table-holder">
                                            <div className="inner-tab-heading">
                                              <h3>LOAD TEST:</h3>
                                            </div>

                                            <div className="description-holder">
                                              <p>
                                                Certified form
                                                BEPL/2018-19/IS/M151 from
                                                Government competent authority
                                                for safe working load - 250 kg.
                                              </p>
                                            </div>
                                          </div>

                                          <div className="Advantages-list-holder">
                                            <h3 className="inner-heading-text">
                                              Advantages:
                                            </h3>
                                            <ul>
                                              <li>
                                                The stairway gives easy ascent
                                                and descent for working,
                                                preferred at continues up down
                                                work.
                                              </li>

                                              <li>
                                                Slanting steps reaches from
                                                platform to platform with 100mm
                                                deep.
                                              </li>
                                              <li>
                                                Platform level can be varied in
                                                height at 280/300 mm intervals,
                                                ensuring comfortable working.
                                              </li>

                                              <li>
                                                Extracted outriggers
                                                (Stabilizer) provide a solid
                                                support.
                                              </li>

                                              <li>
                                                The Retracted outriggers save
                                                space and easy movement.
                                              </li>

                                              <li>
                                                Guardrails frames work as
                                                safeguard.
                                              </li>

                                              <li>
                                                Toe- Board will work as fall
                                                arrester of small parts like
                                                tools form scaffolding.
                                              </li>
                                            </ul>
                                          </div>

                                          <div className="main-table-holder">
                                            <div className="inner-tab-heading">
                                              <h3>Note: </h3>
                                            </div>

                                            <div className="description-holder">
                                              <p>
                                                We provide door to door
                                                replacement policy of parts at
                                                very nominal cost
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                </div>
              </Tab.Container>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Aluminium_Scaffolding_content;
