import React, { useEffect, useState } from "react";
import "./Header.css";
import { Dropdown, Nav, Navbar } from "react-bootstrap";
import { Link, NavLink, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

function Header() {
  // State to track if navbar is expanded
  const [expanded, setExpanded] = useState(false);

  // State to track if the page is scrolled
  const [isScrolled, setIsScrolled] = useState(false);

  // State to track if any dropdown item is selected
  const [isDropdownItemSelected, setIsDropdownItemSelected] = useState(false);

  // Function to handle scroll event
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Function to handle dropdown item click
  const handleDropdownItemClick = () => {
    setIsDropdownItemSelected(true);
    setExpanded(false); // Close the navbar after selection
  };

  // Function to handle non-dropdown nav link click
  const handleNavLinkClick = () => {
    setIsDropdownItemSelected(false);
    setExpanded(false); // Close the navbar after selection
  };

  // Use useLocation to get the current URL path
  const location = useLocation();

  // Check if the current route is a services route
  useEffect(() => {
    if (
      location.pathname === "/scaffolding-on-rent" ||
      location.pathname === "/aluminium-scaffolding" ||
      location.pathname === "/frp-scaffolding" ||
      location.pathname === "/type-ladder-Rent" ||
      location.pathname === "/airport-ladder-rent" ||
      location.pathname === "/scaffolding-accessories"
    ) {
      setIsDropdownItemSelected(true);
    } else {
      setIsDropdownItemSelected(false);
    }
  }, [location]);

  return (
    <>
      <section className={`Header ${isScrolled ? "haeder-top" : ""} `}>
        <div className="container">
          <div
            className={`header_holder ${isScrolled ? "scrollheader" : ""} ${
              expanded ? "white-bg-holder" : ""
            }`}
          >
            <Navbar expand="lg" expanded={expanded} onToggle={setExpanded}>
              <Navbar.Brand as={NavLink} to="/">
                <div className="logo-holder">
                  <img
                    className="headlogo"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/Images/Header/website-logo.png"
                    }
                    alt="Logo"
                  />
                </div>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="navbarScroll" />
              <Navbar.Collapse id="navbarScroll">
                <Nav className="ms-auto my-2 my-lg-0" navbarScroll>
                  <Nav.Link
                    as={NavLink}
                    to="/"
                    activeClassName="active"
                    exact
                    onClick={handleNavLinkClick} // Close navbar on click
                  >
                    Home
                  </Nav.Link>

                  <Dropdown className="services-dropdown_holder ">
                    <Dropdown.Toggle
                      className={`services-dropdown  ${
                        isDropdownItemSelected ? "order-active" : ""
                      }`}
                      id="dropdown-basic"
                    >
                      Services
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        as={Link}
                        to="/scaffolding-on-rent"
                        onClick={handleDropdownItemClick}
                      >
                        Cup-Lock scaffolding on Rent and Sale along with
                        installation and dismantling
                      </Dropdown.Item>
                      <Dropdown.Item
                        as={Link}
                        to="/aluminium-scaffolding"
                        onClick={handleDropdownItemClick}
                      >
                        Certified Aluminium Scaffolding on Rent and Sale
                      </Dropdown.Item>
                      <Dropdown.Item
                        as={Link}
                        to="/frp-scaffolding"
                        onClick={handleDropdownItemClick}
                      >
                        FRP (Fibreglass) Electrical shockproof scaffolding
                        trolley on Rent and Sale
                      </Dropdown.Item>
                      <Dropdown.Item
                        as={Link}
                        to="/type-ladder-Rent"
                        onClick={handleDropdownItemClick}
                      >
                        A type ladder on Rent and Sale
                      </Dropdown.Item>
                      <Dropdown.Item
                        as={Link}
                        to="/airport-ladder-rent"
                        onClick={handleDropdownItemClick}
                      >
                        Airport ladder on Rent and Sale
                      </Dropdown.Item>

                      <Dropdown.Item
                        as={Link}
                        to="/scaffolding-accessories"
                        onClick={handleDropdownItemClick}
                      >
                        Scaffolding Accessories on Rent and Sale
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                  <Nav.Link
                    as={NavLink}
                    to="/about-us"
                    activeClassName="active"
                    onClick={handleNavLinkClick}
                  >
                    About Us
                  </Nav.Link>
                  <Nav.Link
                    as={NavLink}
                    to="/projects"
                    activeClassName="active"
                    onClick={handleNavLinkClick}
                  >
                    Projects
                  </Nav.Link>
                  <Nav.Link
                    as={NavLink}
                    to="/contact-us"
                    activeClassName="active"
                    onClick={handleNavLinkClick}
                  >
                    Contact Us
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </div>
        </div>
      </section>
    </>
  );
}

export default Header;
