import React, { useState, useEffect, useContext } from "react";
import "./Projects_content.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import Projects_Modal from "../../Common_Component/Projects_Modal/Projects_Modal";
import { Context } from "../../../utils/context";

// Sample data for the projects
const projects = [
  {
    title: "SUNBURN FESTIVAL",
    location: "SUNBURN FESTIVAL GOA",
    imgSrc: "/assets/Images/projects/SUNBURN-FESTIVAL-GOA-1.png",
  },
  {
    title: "SUNBURN FESTIVAL",
    location: "SUNBURN FESTIVAL GOA",
    imgSrc: "/assets/Images/projects/SUNBURN-FESTIVAL-GOA-2.png",
  },
  {
    title: "Schindler India Pvt. Ltd.",
    location: "India",
    imgSrc: "/assets/Images/projects/Schindler-india.png",
  },
  {
    title: "Samsung Bkc",
    location: "Mumbai",
    imgSrc: "/assets/Images/projects/Samsung-Bkc-Mumbai.png",
  },
  {
    title: "Wipro",
    location: " Pune",
    imgSrc: "/assets/Images/projects/Wipro-Pune.png",
  },
  {
    title: "Piramal Mahalaxmi",
    location: " Mumbai",
    imgSrc: "/assets/Images/projects/Piramal-Mahalaxmi-Mumbai.png",
  },

  {
    title: "Netflix",
    location: " Mumbai",
    imgSrc: "/assets/Images/projects/Netflix-1-Mumbai.png",
  },

  {
    title: "Netflix",
    location: " Mumbai",
    imgSrc: "/assets/Images/projects/Netflix-2-Mumbai.png",
  },

  {
    title: "Flipkart",
    location: " Mumbai",
    imgSrc: "/assets/Images/projects/Flipkart-Mumbai.png",
  },

  {
    title: "BANGALORE INTERNATIONAL AIRPORT LTD.",
    location: " BANGALORE",
    imgSrc:
      "/assets/Images/projects/BANGALORE-NTERNATIONAL-AIRPORT-LTD-BANGALORE.png",
  },

  {
    title: "Godrej Properties",
    location: "  Mumbai",
    imgSrc: "/assets/Images/projects/Godrej-Properties-Mumbai.png",
  },
];

const Projects_content = ({ project }) => {
  const [modalShow, setModalShow] = React.useState(false);
  const [projectImages, setProjectImages] = React.useState();

  const { IMG_URL } = useContext(Context);

  const handelImage = (images) => {
    setProjectImages(images);
    setModalShow(true);
  };
  return (
    <>
      <section className="About_content_sec Projects_content">
        <div className="container">
          <div className="backgrounddiv Terms_Conditions_content_backgrounddiv">
            <div className="heading-holder-section text-center">
              <h2 className="main-heading">Projects</h2>
              <h3 className="heading">
                Empowering Progress Through Innovation
              </h3>
            </div>
            <div className="abt-contdiv">
              <div className="project-card-holder">
                <div className="row">
                  {project?.map((project, index) => (
                    <div key={index} className="col-lg-4 col-md-6 col-sm-6">
                      <div className="project-card">
                        {/* <div className="img-holder" onClick={() => setModalShow(true)}> */}
                        <div
                          className="img-holder"
                          onClick={() => handelImage(project)}
                        >
                          <img
                            className="projectimg"
                            src={IMG_URL + project?.images[0]?.image_path}
                            alt={project.title}
                          />
                        </div>
                        <div className="text-holder">
                          <h4>{project?.title}</h4>
                          <p>
                            <FontAwesomeIcon
                              className="location-icon"
                              icon={faLocationDot}
                            />{" "}
                            {project?.address}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Projects_Modal
        IMG_URL={IMG_URL}
        projectImages={projectImages}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default Projects_content;
