import React, { useState, useEffect, useContext, useRef } from "react";
import Btn_holder from "../../Common_Component/Btn_holder/Btn_holder";
import { Form } from "react-bootstrap";
import { faGlobe, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { Context } from "../../../utils/context";
import { postContactUs } from "../../../utils/apis/common/Common";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import classNames from "classnames";
import "./Contact_Us_content.css";
import Successfull_Modal from "../../Common_Component/Successfull_Modal/Successfull_Modal";
import "./Contact_Us_content.css";
const Contact_Us_content = () => {
  const { getData, IMG_URL } = useContext(Context);
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    watch,
    trigger,
    setValue,
  } = useForm();
  const [successModal, setSuccessModal] = useState(false);
  const onSubmit = async (data) => {
    console.log(data, "data data");

    const res = await postContactUs(data);
    if (res?.success) {
      setSuccessModal(true);
      setTimeout(() => {
        setSuccessModal(false);
        reset();
      }, 3000);
    } else {
      if (res?.message?.email) {
        setError("email", { message: res?.message?.email });
      }
      if (res?.message?.contact_no) {
        setError("contact_no", { message: res?.message?.contact_no });
      }
    }
  };



  const googleMapRef = useRef(null);
  let googleMap = null;

  const locations = [
    { lat: 18.667503, lng: 73.818985,title:"Head Office & Manufacturing Unit 1:", description: "S. No 560,Sector 16, Raje Shivaji Nagar, Behind Diagonal Mall, Straight to Kudalwadi Road, Next to Rathi Hotel, , Jadhavwadi, Chikhali, Pune - 412114." },
    { lat: 13.017071, lng: 77.496628,title:"Manufacturing Unit 2:", description: "9A, 4th Phase, 4th Cross, Keonics Layout, Peenya, Bangalore – 560058." },
    { lat: 17.397297, lng: 78.382004,title:"Manufacturing Unit 3:", description: "Plot no 45 bayoth colony Near Hyderabad presidency college Manikonda Hyderabad 500089." },
  ];

  // Function to create the Google Map
  const createGoogleMap = () =>
    new window.google.maps.Map(googleMapRef.current, {
      zoom: 5, // Adjust the zoom level based on your needs
      center: { lat: 18.5204, lng: 73.8567 }, // Center of the map (Pune)
    });


    const smoothZoom = (map, targetZoom, currentZoom) => {
      if (currentZoom >= targetZoom) return;
  
      const zoomStep = 0.5; // How much to zoom in each step
      const interval = setInterval(() => {
        if (currentZoom < targetZoom) {
          currentZoom += zoomStep;
          map.setZoom(Math.round(currentZoom));
        } else {
          clearInterval(interval);
        }
      }, 150); // Time interval between each zoom step (in milliseconds)
    };

  // Function to create markers with titles and InfoWindows
  const createMarkers = (map) => {
    locations.forEach((location) => {
      // Create marker
      const marker = new window.google.maps.Marker({
        position: { lat: location.lat, lng: location.lng },
        map: map,
        title: location.title, // Title shows on hover
      });

      // Create InfoWindow to show title and description on click
      const infoWindow = new window.google.maps.InfoWindow({
        content: `<div><h3>${location.title}</h3><p>${location.description}</p></div>`, // Title and Description in InfoWindow
      });

      // Show the InfoWindow on marker click
      marker.addListener('click', () => {
        infoWindow.open(map, marker); // Open InfoWindow

        // Start smooth zoom
        smoothZoom(map, 15, map.getZoom()); // Zoom to level 15, starting from current zoom level
        map.setCenter(marker.getPosition()); // Center the map on the marker's position
      });
    });
  };

  const addZoomOutControl = (map) => {
    const zoomOutButton = document.createElement('div');
    zoomOutButton.style.backgroundColor = '#fff';
    zoomOutButton.style.border = '2px solid #fff';
    zoomOutButton.style.borderRadius = '3px';
    zoomOutButton.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
    zoomOutButton.style.cursor = 'pointer';
    zoomOutButton.style.marginBottom = '22px';
    zoomOutButton.style.textAlign = 'center';
    zoomOutButton.title = 'Click to zoom out';

    // Set button content
    zoomOutButton.innerHTML = 'Zoom Out';
    zoomOutButton.style.padding = '6px';

    // Add click event to zoom out
    zoomOutButton.addEventListener('click', () => {
      const currentZoom = map.getZoom();
      console.log(currentZoom,'currentZoom');
      
      map.setZoom(5); // Decrease zoom level
    });

    // Add the button to the map
    map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(zoomOutButton);
  };

  useEffect(() => {
    // Load the Google Map after the component has mounted
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBDH4cr0QOUxyinBRPLRl60dGEhiIVfu_c`;
    script.async = true;
    script.onload = () => {
      googleMap = createGoogleMap();
      createMarkers(googleMap);
      addZoomOutControl(googleMap);
    };
    document.body.appendChild(script);
  }, []);


  return (
    <>
      <section className="About_content_sec  Contact_Us_content">
        <div className="container">
          <div className="backgrounddiv Contact_Us_content_backgrounddiv ">
            <div className="abt-contdiv">
              <div className="content-holder">
                <div className="heading-holder">
                  <h4 className="heading-text">Get in Touch</h4>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="info-section-holder">
                      <div className="info-holder">
                        <div className="icon-holder">
                          <FontAwesomeIcon className="icon" icon={faGlobe} />
                        </div>
                        <div className="text-holder">
                          <p>www. choudharyscaffoldingpune.in,</p>
                          <p>www.choudharyscaffolding.co.in</p>
                        </div>
                      </div>

                      <div className="info-holder">
                        <div className="icon-holder">
                          <FontAwesomeIcon className="icon" icon={faEnvelope} />
                        </div>
                        <div className="text-holder">
                          <p>choudharyscaffolding@yahoo.com,</p>
                          <p>accounts@costructor.co.in</p>
                        </div>
                      </div>

                      <div className="info-holder">
                        <div className="icon-holder">
                          <FontAwesomeIcon className="icon" icon={faPhone} />
                        </div>
                        <div className="text-holder">
                          <p>+91 7447744901,</p>
                          <p>+91 7447449647</p>
                        </div>
                      </div>
                    </div>

                    <div className="info-card-holder">
                      <div className="card-holder">
                        <div className="top-text-holder">
                          <p>Head Office & Manufacturing Unit 1:</p>
                        </div>
                        <div className="text-holder">
                          {/* <p>
                            <b>CHOUDHARY SCAFFOLDING CONTRACTOR</b>
                          </p> */}
                          <br />
                          <p>
                            S. No 560,Sector 16, Raje Shivaji Nagar, Behind
                            Diagonal Mall, Straight to Kudalwadi Road, Next to
                            Rathi Hotel, , Jadhavwadi, Chikhali, Pune - 412114.
                          </p>
                        </div>
                      </div>

                      <div className="card-holder">
                        <div className="top-text-holder">
                          <p>Manufacturing Unit 2:</p>
                        </div>
                        <div className="text-holder">
                          <p>
                            9A, 4th Phase, 4th Cross, Keonics Layout, Peenya,
                            Bangalore – 560058
                          </p>
                        </div>
                      </div>

                      <div className="card-holder">
                        <div className="top-text-holder">
                          <p>Manufacturing Unit 3:</p>
                        </div>
                        <div className="text-holder">
                          <p>
                            Plot no 45 bayoth colony Near Hyderabad presidency
                            college Manikonda Hyderabad 500089
                          </p>
                        </div>
                      </div>

                      <div className="card-holder">
                        <div className="top-text-holder">
                          <p>BRANCH & WAREHOUSE:</p>
                        </div>
                        <div className="text-holder">
                          <ul>
                            <li>
                              <p> Mumbai </p>
                            </li>
                            <li>
                              <p> Chennai </p>
                            </li>

                            <li>
                              <p>Goa</p>
                            </li>

                            <li>
                              <p> Delhi</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="contact_form_holder">
                      <div>
                        <div className="form_holder form-section">
                          <Form>
                            <div className="row">
                              <div className="col-xl-6 col-md-6 col-sm-6 col-12">
                                <Form.Group
                                  className="Form-field-holder"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Enter First Name</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="name"
                                    placeholder="Enter First Name"
                                    {...register("first_name", {
                                      required: "Name is required",
                                    })}
                                    className={classNames("", {
                                      "is-invalid": errors?.first_name,
                                    })}
                                    onKeyDown={(event) => {
                                      if (!/[A-Z-a-z ]/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                  />
                                  {errors.first_name && (
                                    <div className="invalid-feedback">
                                      {errors.first_name.message}
                                    </div>
                                  )}
                                </Form.Group>
                              </div>

                              <div className="col-xl-6 col-md-6 col-sm-6 col-12">
                                <Form.Group
                                  className="Form-field-holder"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Enter Last Name</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="name"
                                    placeholder="Enter Last Name"
                                    {...register("last_name", {
                                      required: "Name is required",
                                    })}
                                    className={classNames("", {
                                      "is-invalid": errors?.last_name,
                                    })}
                                    onKeyDown={(event) => {
                                      if (!/[A-Z-a-z ]/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                  />
                                  {errors.last_name && (
                                    <div className="invalid-feedback">
                                      {errors.last_name.message}
                                    </div>
                                  )}
                                </Form.Group>
                              </div>

                              <div className="col-xl-6 col-md-6 col-sm-6 col-12">
                                <Form.Group
                                  className="Form-field-holder"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Enter Mail ID</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="email"
                                    placeholder="E-mail Address"
                                    {...register("email", {
                                      required: "Email Id required",
                                      pattern: {
                                        value:
                                          /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z0-9_\-\.]+)\.\2)([A-Za-z0-9_\-\.]+\.)+([A-Za-z]{2,4})$/,
                                        message: "Email Id Invalid",
                                      },
                                    })}
                                    className={classNames("", {
                                      "is-invalid": errors?.email,
                                    })}
                                  />
                                  {errors.email && (
                                    <div className="invalid-feedback">
                                      {errors.email.message}
                                    </div>
                                  )}
                                </Form.Group>
                              </div>

                              <div className="col-xl-6 col-md-6 col-sm-6 col-12">
                                <Form.Group
                                  className="Form-field-holder"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Enter Contact Number</Form.Label>
                                  <Form.Control
                                    className={classNames("", {
                                      "is-invalid": errors?.contact_number,
                                      "is-valid": getValues("contact_number"),
                                    })}
                                    type="text"
                                    name="contact_number"
                                    placeholder="Enter Mobile Number"
                                    {...register("contact_number", {
                                      required: "Contact No is required",
                                      minLength: {
                                        value: 10,
                                        message:
                                          "Number should be at least 10 characters",
                                      },
                                    })}
                                    onKeyDown={(event) => {
                                      if (!/[0-9]/.test(event.key)) {
                                        if (event.key !== "Backspace") {
                                          event.preventDefault();
                                        }
                                      }
                                    }}
                                    maxLength={10}
                                  />
                                  {errors?.contact_number && (
                                    <sup className="text-danger">
                                      {errors?.contact_number?.message}
                                    </sup>
                                  )}
                                </Form.Group>
                              </div>

                              <div className="col-12">
                                <Form.Group
                                  className="Form-field-holder"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Enter Message</Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    name="message"
                                    type="text"
                                    rows={4}
                                    placeholder="Enter Message"
                                    {...register("message", {
                                      required: "Message required",
                                    })}
                                    className={classNames("", {
                                      "is-invalid": errors?.message,
                                    })}
                                  />
                                  {errors.message && (
                                    <div className="invalid-feedback">
                                      {errors.message.message}
                                    </div>
                                  )}
                                </Form.Group>
                              </div>
                            </div>
                          </Form>
                        </div>

                        <div className="btn-holder">
                          <Btn_holder
                            onClick={handleSubmit(onSubmit)}
                            className={"common_btn"}
                            text={"Submit"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="map-holder">
                <div id="google-maps-canvas">
                  {/* <iframe
                    frameBorder="0"
                    src="https://www.google.com/maps/embed/v1/place?q=Office+No.+104,+Morya+Business+center,+JP+-+115,+G+-+Block,+Sambhaji+Nagar,+MIDC+Chinchwad,+Pune+-411019&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
                    allowFullScreen
                  ></iframe> */}
                  <div
                    id="google-map"
                    ref={googleMapRef}
                    style={{ width: '100%', height: '500px' }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Successfull_Modal
          show={successModal}
          onHide={() => setSuccessModal(false)}
          message={"Contact Us Sent Successfully"}
        />
      </section>
    </>
  );
};

export default Contact_Us_content;
