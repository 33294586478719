import React, { useState, useEffect, useContext } from "react";
import Projects_Banner from "./Projects_Banner/Projects_Banner";
import Projects_content from "./Projects_content/Projects_content";
import { getAllProject } from "../../utils/apis/common/Common";
function Projects() {
  const [project, setProject] = useState();
  const getProject = async () => {
    const res = await getAllProject();
   
    if (res?.success) {
      setProject(res?.data?.list);
    } else {
        console.error('Error fetching  data:', res?.error);
    }
}

useEffect(() => {
    getProject();
}, []);
console.log(project,"project project");
  return (
    <>
      <Projects_Banner />
      
      {project?.length > 0 && <Projects_content project={project} />}
     

     
    </>
  );
}

export default Projects;
