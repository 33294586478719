import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";

function Airport_ladder_slider_section() {
  const [swiperInstance, setSwiperInstance] = useState(null);
  const [selectedImage, setSelectedImage] = useState(
    "/assets/Images/Services/Airport-ladder.png"
  ); // Default selected image

  const handleMainSliderClick = (direction) => {
    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };

  const handleImageClick = (imgSrc) => {
    setSelectedImage(imgSrc); // Update selected image on click
  };

  return (
    <section className="Services_slider_section">
      <div className="row">
        <div className="col-lg-3 col-3">
          <Swiper
            slidesPerView={"auto"}
            breakpoints={{
              0: {
                slidesPerView: 4,
                spaceBetween: 10,
              },
              370: {
                slidesPerView: 4,
                spaceBetween: 10,
              },
              485: {
                slidesPerView: 4,
                spaceBetween: 10,
              },
              575: {
                slidesPerView: 4,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 4,
                spaceBetween: 10,
              },
              992: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              1200: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              1440: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              2000: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
            }}
            navigation={{ clickable: true }}
            direction={"vertical"}
            className="Services_slider"
            onSwiper={(swiper) => setSwiperInstance(swiper)} // Set Swiper instance
          >
            <SwiperSlide>
              <div className="big-img-holder">
                <img
                  className="Servive-img"
                  src="/assets/Images/Services/Airport-ladder.png"
                  onClick={() =>
                    handleImageClick(
                      "/assets/Images/Services/Airport-ladder.png"
                    )
                  } // Update image on click
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="big-img-holder">
                <img
                  className="Servive-img"
                  src="/assets/Images/Services/Airport-ladder.png"
                  onClick={() =>
                    handleImageClick(
                      "/assets/Images/Services/Airport-ladder.png"
                    )
                  } // Update image on click
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="big-img-holder">
                <img
                  className="Servive-img"
                  src="/assets/Images/Services/Airport-ladder.png"
                  onClick={() =>
                    handleImageClick(
                      "/assets/Images/Services/Airport-ladder.png"
                    )
                  } // Update image on click
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="big-img-holder">
                <img
                  className="Servive-img"
                  src="/assets/Images/Services/Airport-ladder.png"
                  onClick={() =>
                    handleImageClick(
                      "/assets/Images/Services/Airport-ladder.png"
                    )
                  } // Update image on click
                />
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="big-img-holder">
                <img
                  className="Servive-img"
                  src="/assets/Images/Services/Airport-ladder.png"
                  onClick={() =>
                    handleImageClick(
                      "/assets/Images/Services/Airport-ladder.png"
                    )
                  } // Update image on click
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="big-img-holder">
                <img
                  className="Servive-img"
                  src="/assets/Images/Services/Airport-ladder.png"
                  onClick={() =>
                    handleImageClick(
                      "/assets/Images/Services/Airport-ladder.png"
                    )
                  } // Update image on click
                />
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="big-img-holder">
                <img
                  className="Servive-img"
                  src="/assets/Images/Services/Airport-ladder.png"
                  onClick={() =>
                    handleImageClick(
                      "/assets/Images/Services/Airport-ladder.png"
                    )
                  } // Update image on click
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="big-img-holder">
                <img
                  className="Servive-img"
                  src="/assets/Images/Services/Airport-ladder.png"
                  onClick={() =>
                    handleImageClick(
                      "/assets/Images/Services/Airport-ladder.png"
                    )
                  } // Update image on click
                />
              </div>
            </SwiperSlide>
          </Swiper>

          <div className="silder-btn">
            <div
              className="back-btn"
              onClick={() => handleMainSliderClick("prev")}
            >
              <FontAwesomeIcon icon={faAngleLeft} />
            </div>
            <div
              className="next-btn"
              onClick={() => handleMainSliderClick("next")}
            >
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </div>
        </div>
        <div className="col-lg-7 col-9">
          <div className="big-img-holder">
            <img className="Servive-img" src={selectedImage} alt="Selected" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Airport_ladder_slider_section;
