import React from "react";
import "./App.css";
import "./index.css";
import "./Component/Common_css/Common_css.css";
import { Route, Routes } from "react-router-dom";
import Header from "./Component/Header/Header";
import Footer from "./Component/Footer/Footer";
import Home from "./Component/Home/Home";
import About_Us from "./Component/About_Us/About_Us";
import Projects from "./Component/Projects/Projects";
import Contact_Us from "./Component/Contact_Us/Contact_Us";
import ScrollToTop from "./Component/ScrollTotop/ScrollTotop";
import Terms_Conditions from "./Component/Terms_Conditions/Terms_Conditions";
import Privacy_Policy from "./Component/Privacy_Policy/Privacy_Policy";
import Scaffolding_on_Rent from "./Component/Services/Scaffolding_on_Rent/Scaffolding_on_Rent";
import Scaffolding_Accessories from "./Component/Services/Scaffolding_Accessories/Scaffolding_Accessories";
import FRP_scaffolding from "./Component/Services/FRP_scaffolding/FRP_scaffolding";
import Type_Ladder_Rent_Content from "./Component/Services/Type_Ladder_Rent/Type_Ladder_Rent";
import Type_Ladder_Rent from "./Component/Services/Type_Ladder_Rent/Type_Ladder_Rent";
import Airport_Ladder_Rent from "./Component/Services/Airport_Ladder_Rent/Airport_Ladder_Rent";
import AppContext from "./utils/context";
import Aluminium_Scaffolding from "./Component/Services/Aluminium_Scaffolding/Aluminium_Scaffolding";
function App() {
  return (
    <>
      <ScrollToTop />

      <Header />
      <AppContext>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<About_Us />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/contact-us" element={<Contact_Us />} />
        <Route path="/terms-and-conditions" element={<Terms_Conditions />} />
        <Route path="/privacy-policy" element={<Privacy_Policy />} />
        <Route path="/scaffolding-on-rent" element={<Scaffolding_on_Rent />} />
        <Route path="/aluminium-scaffolding" element={<Aluminium_Scaffolding />} />
        <Route path="/scaffolding-accessories" element={<Scaffolding_Accessories />} />
        <Route path="/frp-scaffolding" element={<FRP_scaffolding />} />
        <Route path="/type-ladder-Rent" element={<Type_Ladder_Rent />} />
        <Route path="/airport-ladder-rent" element={<Airport_Ladder_Rent />} />
      </Routes>
      <Footer />
      </AppContext>
    </>
  );
}

export default App;
