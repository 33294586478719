import React, { useState, useEffect, useContext } from "react";
import "./Home.css";
import HomeBanner from "./HomeBanner/HomeBanner";
import OurProfessionalism from "./OurProfessionalism/OurProfessionalism";
import Our_Success from "./Our_Success/Our_Success";
import Our_Services from "./Our_Services/Our_Services";
import Walking_With_Giants from "./Walking_With_Giants/Walking_With_Giants";
import Your_Inquiries from "./Your_Inquiries/Your_Inquiries";
import Customer_Satisfaction_Stories from "./Customer_Satisfaction_Stories/Customer_Satisfaction_Stories";
import About_whyschooseus from "../About_Us/abt-whyschoose/About_whyschooseus";
import { Context } from "../../utils/context";
import { getAllExcellence,getAllClient,getSuccess,getBanner } from "../../utils/apis/common/Common";
function Home() {
  const [data, setData] = useState();
  const {  IMG_URL } = useContext(Context);
  const getAllData = async () => {
      const res = await getAllExcellence();
   
      if (res?.success) {
          setData(res?.data?.list);
      } else {
          console.error('Error fetching  data:', res?.error);
      }
  }
  const [dataBrand, setDataBrand] = useState();
  const getAllDatabrand = async () => {
    const res = await getAllClient();
    
    if (res?.success) {
      setDataBrand(res?.data?.list);
    } else {
        console.error('Error fetching  data:', res?.error);
    }
}

const [dataSucces, setDataSucces] = useState();
  
  const getAllDataSucces = async () => {
      const res = await getSuccess();
     
      if (res?.success) {
        setDataSucces(res?.data?.list);
      } else {
          console.error('Error fetching  data:', res?.error);
      }
  }

  const [dataBanner, setDataBanner] = useState();
  
  const getAllDataBanner = async () => {
      const res = await getBanner();
     
      if (res?.success) {
        setDataBanner(res?.data?.list);
      } else {
          console.error('Error fetching  data:', res?.error);
      }
  }

  useEffect(() => {
    getAllDataBanner();
    getAllDatabrand();
      getAllData();
      getAllDataSucces();
  }, []);
  return (
    <>
    {dataBanner && <HomeBanner data={dataBanner} IMG_URL={IMG_URL}/>}
 
      <OurProfessionalism />
      {dataSucces?.length > 0 && <Our_Success data={dataSucces} IMG_URL={IMG_URL}/>}
      
      <Our_Services />
      {data?.length > 0 && <About_whyschooseus data={data} IMG_URL={IMG_URL}/>}
      {dataBrand?.length > 0 && <Walking_With_Giants data={dataBrand} IMG_URL={IMG_URL}/>}
      {/* <Walking_With_Giants /> */}
      <Your_Inquiries />
      <Customer_Satisfaction_Stories />
    </>
  );
}

export default Home;
