import React from "react";
import "./HomeBanner.css";
import Header from "../../Header/Header";
import { Button } from "react-bootstrap";
import Btn_holder from "../../Common_Component/Btn_holder/Btn_holder";
import { Link } from "react-router-dom";

function HomeBanner({data,IMG_URL}) {
  return (
    <>
      <section className="HomeBanner">
        <div className="HomeBanner_vdo_holder">
          <video
            className="HomeBanner_vdo"
            src={`${IMG_URL}${data?.image}`} 
            // src="/assets/Video/HomeBanner/HomeBanner_vdo.mp4"
            type="video/mp4"
            loop
            muted
            autoPlay
          />
        </div>
        <div className="container">
          <div className="banner-content-main">
            <div className="HomeBanner_content_holder">
              <div className="banner-header-holder">
                <h3>Creating access to your Imagination</h3>
                <p>
                  Leading service provider in MS Cuplock and Aluminum
                  Scaffolding
                </p>
                <div className="btn-holder">
                  {/* <Button className="contact-btn">Contact Us</Button> */}
                  <Link to="/contact-us">
                  <Btn_holder className={"contact-btn"} text={"Contact Us"}/>
                  </Link>
                </div>
              </div>
              <div className="hr-line">
                <div className="text-holder">
                  <p>Safety </p>
                  <div className="vr"></div>
                  <p>Service </p>
                  <div className="vr"></div>
                  <p>Commitment </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="overlay-holder"></div>
      </section>
    </>
  );
}

export default HomeBanner;
